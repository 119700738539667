jQuery.fn.setEqualHeightsPerRow = function(givenOptions){
	var defaultOptions = {
		debug: false,
		createResizeEvent: true,
		createLoadEvent: true,
		minHeight: 0,
		perRow: -1,
		calcColEveryRow: false,
		delayResizeEvent: 300,
		heightProperty : 'height',
		boxSizing: 'borderbox',
		functionAfterResize: null,
		functionBeforeResize: null,
	};
		
	var options = typeof givenOptions == 'object' ? jQuery.extend(defaultOptions,givenOptions) : defaultOptions;
	
	var $elems = jQuery(this);
    var counter = 0;
    
    var newCss = {};
	newCss[options.heightProperty] = 'auto';
    $elems.css(newCss);
    
	if(options.debug){
		console.log($elems);
		$elems.each(function(ind,elem){
			console.log("offset elem "+(ind)+": "+$elems.eq(ind).offset().top);
		});
	}
	if(options.perRow == -1){
		var perRow = 0;
	    $elems.each(function(ind,elem){
	        if(ind != 0 && $elems.eq(ind).offset().top != $elems.eq(ind-1).offset().top) return false;
	        perRow++;
	    });
	} else{
		perRow = options.perRow;
	}
    if(perRow > 1 || options.calcColEveryRow){
	    while(counter < $elems.length){
	    	if(counter != 0 && options.calcColEveryRow){
	    		perRow = 0;
	    	    $elems.slice(counter).each(function(ind,elem){
	    	        if(ind != 0 && $elems.eq(ind+counter).offset().top != $elems.eq((counter+ind)-1).offset().top) return false;
	    	        perRow++;
	    	    });
	    	}
	    	if(perRow < 2){
	    		counter++;
	    		continue;
	    	}
	        var height = options.minHeight;
	        for(var i = 0; i < perRow; i++){
	        	
	        	var boxS = options.boxSizing;
	        	if(boxS == 'auto'){
	        		boxS = $elems.eq(i+counter).css('box-sizing') == 'content-box' ? 'contentbox' : 'borderbox';
	        	}
	        	if (boxS== 'contentbox') {
					height = $elems.eq(i+counter).height() > height ? $elems.eq(i+counter).height() : height;
				} else{
	            	height = $elems.eq(i+counter).outerHeight(false) > height ? $elems.eq(i+counter).outerHeight(false) : height;
	            }
	        }
	        if(height > 0){
		        newCss = {};
				newCss[options.heightProperty] = height;
		        $elems.slice(counter, counter+perRow).css(newCss);
	        }
	        counter += perRow;
	    }
    } else if(options.minHeight != 0){
        newCss = {}; newCss[options.heightProperty] = options.minHeight;
        $elems.css(newCss);
    }
    if(options.debug){
    	$elems.each(function(ind,elem){
    		console.log("Height elem "+(ind+1)+": "+jQuery(elem).height());
    	});
    }
    if(options.createLoadEvent){
    	jQuery(window).on('load', function() {
			$elems.setEqualHeightsPerRow(jQuery.extend(options, {createLoadEvent: false, createResizeEvent: false}));
		});
    }
    var self = this;
    if(options.createResizeEvent){
    	wwdev.addResizeFunction(function(){
    		if(options.functionBeforeResize)
    			options.functionBeforeResize.apply(self);
	  		$elems.setEqualHeightsPerRow(jQuery.extend(options, {createLoadEvent: false, createResizeEvent: false}));
	  		if(options.functionAfterResize)
	  			options.functionAfterResize.apply(self);
		},options.delayResizeEvent);
    }
};