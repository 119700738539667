import './bootstrap';

import './extern/jquery-ui-1.12.1.min.js';
import './common/onScreen.js';
import './common/jquery.fancybox.min.js';
import './extern/jquery.validate.js';
import './common/setEqualHeightsPerRow.js';
import './common/wwdev.Bootstrap.js';
import './extern/jquery.dataTables.min.js';
import './local/default.js';
import './common/wwdev.extender.js';