function numberToPrice(number) {
	number = (Math.ceil(number*100)/100)+ ' ';
	number = number.replace(',','.');
	var e = number.split('.');
	var ext = '00';
	if (e[1]) {
		if (e[1].length == 1) {
			ext = e[1] + '0';
		} else {
			ext = e[1];
		}
	}
	number = e[0] + ',' + ext;
	return number;
}

function isKvKNumber(number) {
	var reg = new RegExp('^[0-9]+$');
	if (number.length == 8 && reg.test(number)) {
		return true;
	}
	return false;
}

function isAdult(day, month, year) {
	year = parseInt(year);
	month = parseInt(month);
	day = parseInt(day);
	return new Date(year+18, month-1, day) <= new Date();
}

wwdev.Bootstrap.validating = function() {
	if (jQuery.validator) {
		jQuery.extend(jQuery.validator.messages, {
			required: "<i class='fa fa-times'></i> Dit is een verplicht veld.",
			remote: "<i class='fa fa-times'></i> Dit e-mailadres is al bekend bij ons.",
			email: "<i class='fa fa-times'></i> Vul hier een geldig e-mailadres in.",
			url: "<i class='fa fa-times'></i> Dit is een verplicht veld.",
			date: "<i class='fa fa-times'></i> Vul hier een geldige datum in.",
			dateISO: "<i class='fa fa-times'></i> Vul hier een geldige datum in (ISO).",
			number: "<i class='fa fa-times'></i> Vul hier een geldig nummer in.",
			digits: "<i class='fa fa-times'></i> Vul hier alleen nummers in.",
			creditcard: "<i class='fa fa-times'></i> Vul hier een geldig credit card nummer in.",
			equalTo: "<i class='fa fa-times'></i> Vul hier dezelfde waarde in.",
			accept: "<i class='fa fa-times'></i> Vul hier een waarde in met een geldige extensie.",
			maxlength: "<i class='fa fa-times'></i> Vul hier maximaal {0} tekens in.",
			minlength: "<i class='fa fa-times'></i> Vul hier minimaal {0} tekens in.",
			rangelenth: "<i class='fa fa-times'></i> Vul hier een waarde in van minimaal {0} en maximaal {1} tekens.",
			range: "<i class='fa fa-times'></i> Vul hier een waarde in van minimaal {0} en maximaal {1}.",
			max: "<i class='fa fa-times'></i> Vul hier een waarde in kleiner dan of gelijk aan {0}.",
			min: "<i class='fa fa-times'></i> Vul hier een waarde in groter dan of gelijk aan {0}.",
		}, typeof translation == 'object' && typeof translation.form == 'object' ? translation.form : {});

	}

	jQuery.validator.addMethod( "alfa", function( value, element ) {
		if (value.match(/^[^0-9]+$/g)) {
			return true;
		}
		return false;
	}, "Er mogen hier geen cijfers ingevuld worden.");


	jQuery.validator.addMethod("regex", function( value, element ) {
		if (jQuery(element).attr('data-regex')) {
			var regex = jQuery(element).attr('data-regex');
			var re = new RegExp(regex);
			if ((!jQuery(element).hasClass('required') && value === '') || re.test(value)) {
				return true;
			}
		}
		return false;
	}, "Dit veld is onjuist ingevuld.");

	setTimeout(function() {
		jQuery('.usernameremote').rules('add', {
			remote: "/ajax/username-exists-new-customer",
			email: true,
			onkeyup: false,
			messages: {
				remote: "<i class='fa fa-times'></i> Deze gebruikersnaam is al in gebruik"
			}
		});
	}, 0);

	jQuery.validator.addMethod("repeat", function( value, element ) {
		var mainfield = jQuery('#' + jQuery(element).attr('repeat-field'));
		if (mainfield.val() === value) {
			return true;
		}
		return false;
	}, "De opgegeven wachtwoorden komen niet overeen.");

	jQuery.validator.addMethod("username", function( value, element ) {
		if (value.match(/^[0-9a-zA-Z\-_]+$/g) && value.length >= 4) {
			return true;
		}
		return false;
	}, "Een geldige gebruikersnaam moet bestaan uit minimaal 4 karakters.");

	jQuery.validator.addMethod("passwordByEmail", function (value, element) {
		if (value == '') {
			var email = jQuery('input.emailWithPassword').val();
			if (email == '' || email == '@kliksafe.nl') {
				return true;
			}
		}
		return false;
	}, "Wanneer u een gewenst mailadres invult, moet u ook een wachtwoord voor dit mailadres invullen.");

	jQuery.validator.addMethod( "password10", function( value, element ) {
		value = value.replace(' ','');
		if (value.match(/[0-9]/g) && value.match(/[a-z]/g) && value.match(/[A-Z]/g) && value.match(/[^A-Za-z0-9]/g) && value.length >= 10 && !value.match(/([A-Za-z0-9])\1{2}/g)) {
			return true;
		}
		return false;
	}, "Verplicht: minimaal 10 karakters, waaronder 1 hoofd- en kleine letter, 1 cijfer en 1 bijzonder teken. Karakters mogen niet meer dan 2 keer achter elkaar voorkomen.");

	jQuery.validator.addMethod( "password10-nonrequired", function( value, element ) {
		value = value.replace(' ','');
		if (value == '') {
			return true;
		}
		if (value.match(/[0-9]/g) && value.match(/[a-z]/g) && value.match(/[A-Z]/g) && value.match(/[^A-Za-z0-9]/g) && value.length >= 10 && !value.match(/([A-Za-z0-9])\1{2}/g)) {
			return true;
		}
		return false;
	}, "Verplicht: minimaal 10 karakters, waaronder 1 hoofd- en kleine letter, 1 cijfer en 1 bijzonder teken. Karakters mogen niet meer dan 2 keer achter elkaar voorkomen.");



	var specialmsgzipcode = "";

	jQuery.validator.addMethod("zipcode", function (value, element) {
		if (value != '') {
			value = value.toUpperCase().replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '');
			if (value.match(/^[1-9]{1}[0-9]{3}[A-Z]{2}$/g)) {
				return true;
			} else {
				specialmsgzipcode = "Vul een juiste postcode in.";
			}
		} else {
			specialmsgzipcode = "Vul een juiste postcode in.";
		}
		return false;
	}, function () {
		return specialmsgzipcode;
	});

	jQuery.validator.addMethod("phonenumber-all", function (value, element) {
		value = value.replace('-', '').replace('+', '').trim().replace(' ', '').replace(' ', '').replace(' ', '');
		if (value.match(/^[0-9]{10}$/g) || value == '') {
			return true;
		}
		return false;
	}, "Vul een 10 cijferig telefoonnummer in.");

	jQuery.validator.addMethod("phonenumber", function (value, element) {
		value = value.replace('-', '').replace('+', '').trim().replace(' ', '').replace(' ', '').replace(' ', '');
		if (value.match(/^[0-9]{10}$/g) || value == '') {
			if (!value.match(/^06/g)) {
				return true;
			}
		}
		return false;
	}, "Vul een 10 cijferig telefoonnummer (geen mobiel nummer).");

	jQuery.validator.addMethod("mobile", function (value, element) {
		if (value != '') {
			value = value.replace('-', '').replace('+', '').trim().replace(' ', '').replace(' ', '').replace(' ', '');
			if (value.match(/^[0-9]{10}$/g)) {
				return true;
			}
			return false;
		}
		return true;
	}, "Vul een 10 cijferig mobiele nummer in.");


	jQuery.validator.addMethod("iban", function (value, element) {

		// Some quick simple tests to prevent needless work
		if (this.optional(element)) {
			return true;
		}

		// Remove spaces and to upper case
		var iban = value.replace(/ /g, "").toUpperCase(),
			ibancheckdigits = "",
			leadingZeroes = true,
			cRest = "",
			cOperator = "",
			countrycode, ibancheck, charAt, cChar, bbanpattern, bbancountrypatterns, ibanregexp, i, p;

		// Check for IBAN code length.
		// It contains:
		// country code ISO 3166-1 - two letters,
		// two check digits,
		// Basic Bank Account Number (BBAN) - up to 30 chars
		var minimalIBANlength = 5;
		if (iban.length < minimalIBANlength) {
			return false;
		}

		// Check the country code and find the country specific format
		countrycode = iban.substring(0, 2);
		bbancountrypatterns = {
			"AL": "\\d{8}[\\dA-Z]{16}",
			"AD": "\\d{8}[\\dA-Z]{12}",
			"AT": "\\d{16}",
			"AZ": "[\\dA-Z]{4}\\d{20}",
			"BE": "\\d{12}",
			"BH": "[A-Z]{4}[\\dA-Z]{14}",
			"BA": "\\d{16}",
			"BR": "\\d{23}[A-Z][\\dA-Z]",
			"BG": "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
			"CR": "\\d{17}",
			"HR": "\\d{17}",
			"CY": "\\d{8}[\\dA-Z]{16}",
			"CZ": "\\d{20}",
			"DK": "\\d{14}",
			"DO": "[A-Z]{4}\\d{20}",
			"EE": "\\d{16}",
			"FO": "\\d{14}",
			"FI": "\\d{14}",
			"FR": "\\d{10}[\\dA-Z]{11}\\d{2}",
			"GE": "[\\dA-Z]{2}\\d{16}",
			"DE": "\\d{18}",
			"GI": "[A-Z]{4}[\\dA-Z]{15}",
			"GR": "\\d{7}[\\dA-Z]{16}",
			"GL": "\\d{14}",
			"GT": "[\\dA-Z]{4}[\\dA-Z]{20}",
			"HU": "\\d{24}",
			"IS": "\\d{22}",
			"IE": "[\\dA-Z]{4}\\d{14}",
			"IL": "\\d{19}",
			"IT": "[A-Z]\\d{10}[\\dA-Z]{12}",
			"KZ": "\\d{3}[\\dA-Z]{13}",
			"KW": "[A-Z]{4}[\\dA-Z]{22}",
			"LV": "[A-Z]{4}[\\dA-Z]{13}",
			"LB": "\\d{4}[\\dA-Z]{20}",
			"LI": "\\d{5}[\\dA-Z]{12}",
			"LT": "\\d{16}",
			"LU": "\\d{3}[\\dA-Z]{13}",
			"MK": "\\d{3}[\\dA-Z]{10}\\d{2}",
			"MT": "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
			"MR": "\\d{23}",
			"MU": "[A-Z]{4}\\d{19}[A-Z]{3}",
			"MC": "\\d{10}[\\dA-Z]{11}\\d{2}",
			"MD": "[\\dA-Z]{2}\\d{18}",
			"ME": "\\d{18}",
			"NL": "[A-Z]{4}\\d{10}",
			"NO": "\\d{11}",
			"PK": "[\\dA-Z]{4}\\d{16}",
			"PS": "[\\dA-Z]{4}\\d{21}",
			"PL": "\\d{24}",
			"PT": "\\d{21}",
			"RO": "[A-Z]{4}[\\dA-Z]{16}",
			"SM": "[A-Z]\\d{10}[\\dA-Z]{12}",
			"SA": "\\d{2}[\\dA-Z]{18}",
			"RS": "\\d{18}",
			"SK": "\\d{20}",
			"SI": "\\d{15}",
			"ES": "\\d{20}",
			"SE": "\\d{20}",
			"CH": "\\d{5}[\\dA-Z]{12}",
			"TN": "\\d{20}",
			"TR": "\\d{5}[\\dA-Z]{17}",
			"AE": "\\d{3}\\d{16}",
			"GB": "[A-Z]{4}\\d{14}",
			"VG": "[\\dA-Z]{4}\\d{16}"
		};

		bbanpattern = bbancountrypatterns[countrycode];

		// As new countries will start using IBAN in the
		// future, we only check if the countrycode is known.
		// This prevents false negatives, while almost all
		// false positives introduced by this, will be caught
		// by the checksum validation below anyway.
		// Strict checking should return FALSE for unknown
		// countries.
		if (typeof bbanpattern !== "undefined") {
			ibanregexp = new RegExp("^[A-Z]{2}\\d{2}" + bbanpattern + "$", "");
			if (!( ibanregexp.test(iban) )) {
				return false; // Invalid country specific format
			}
		}

		// Now check the checksum, first convert to digits
		ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4);
		for (i = 0; i < ibancheck.length; i++) {
			charAt = ibancheck.charAt(i);
			if (charAt !== "0") {
				leadingZeroes = false;
			}
			if (!leadingZeroes) {
				ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(charAt);
			}
		}

		// Calculate the result of: ibancheckdigits % 97
		for (p = 0; p < ibancheckdigits.length; p++) {
			cChar = ibancheckdigits.charAt(p);
			cOperator = "" + cRest + "" + cChar;
			cRest = cOperator % 97;
		}
		return cRest === 1;
	}, "Vul een geldige IBAN in");

	jQuery.validator.addMethod("password14", function( value, element ) {
		value = value.replace(/\s/g,'');
		if(value.length >= 14) {
			return true;
		}
		return false;
	}, "Het wachtwoord moet tenminste 14 karakters lang zijn.");

	jQuery.validator.addMethod("password14-notrequired", function( value, element ) {
		value = value.replace(/\s/g,'');
		if(value.length >= 14 || value.length < 1) {
			return true;
		}
		return false;
	}, "Het wachtwoord moet tenminste 14 karakters lang zijn.");

	jQuery.validator.addMethod("alfanumemail", function( value, element ) {
		if (value.match(/^[0-9a-zA-Z]{1}[0-9a-zA-Z\_\-\.]+[0-9a-zA-Z]{1}$/g)) {
			return true;
		}
		return false;
	}, "U kunt alleen getallen, letters en een punt of een streepje gebruiken. U moet beginnen en eindigen met een getal of letter.");

	jQuery.validator.addMethod('isadult', function (value, element) {
		if (jQuery(element).attr('id') === 'birth-year') {
			year = value;
			month = jQuery('#birth-month').val();
			day = jQuery('#birth-day').val();

			setTimeout(function() {
				if (jQuery('div.newuser-data .contract_box.column-50 .box_content').length > 0) {
					jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
					jQuery('div.newuser-data .contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
					jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
				}
			}, 300);

			if (isAdult(day, month, year)) {
				return true;
			}
			return false;
		}
		return false;
	}, "We zien aan je geboortedatum dat je jonger bent dan 18 jaar. Helaas kan je dan geen abonnement bij ons aanschaffen. Vraag je ouders of een andere volwassene om de aanvraag in te vullen.");


	jQuery.validator.addMethod('kvk', function (value, element) {
		if (isKvKNumber(value)) {
			return true;
		}
		return false;
	}, "Dit is geen officieel KvK-nummer.");
};

wwdev.Bootstrap.makeRequired = function() {
	jQuery('.wizard input.required, .hidden-stuff input.required').each(function() {
		if (!jQuery(this).hasClass('no-actions')) {
			if (jQuery(this).is(':visible')) {
				jQuery(this).prop('required', true);
			} else {
				jQuery(this).prop('required', false);
			}
		}
	});
};

wwdev.Bootstrap.makeDisabled = function() {
	jQuery('.wizard input:not(.disabled), .hidden-stuff input:not(.disabled)').each(function() {
			if (!jQuery(this).hasClass('no-actions') && jQuery(this).parent().prop('tagName') !== 'FORM') {
					if (jQuery(this).parent().is(':visible')) {
						jQuery(this).prop('disabled', false);
					} else {
						jQuery(this).prop('disabled', 'disabled');
					}
			}
	});
};
wwdev.Bootstrap.wizard = function() {
	// wizard
	this.makeRequired();
	this.makeDisabled();

	jQuery('.wizard input[type=checkbox].notoggle').on('click', function(event) {
		alert('Dit product hoort bij het basispakket en kan niet uitgevinkt worden.');
		event.stopPropagation();
		event.stopImmediatePropagation();
		return false;
	});
	jQuery('.wizard input[type=radio]').on('change, click', function() {
		jQuery(this).parent().parent().parent().find('ul').removeClass('open');
		var ul = jQuery(this).parent().parent().find('> ul');
		if (ul.length) {
			ul.addClass('open');
		}
		wwdev.Bootstrap.makeRequired();
		wwdev.Bootstrap.makeDisabled();
	});
	jQuery('.wizard input[type=checkbox]').on('change, click', function() {
		if (jQuery(this).is(':checked')) {
			jQuery(this).parent().parent().find('ul').addClass('open');
		} else {
			jQuery(this).parent().parent().find('ul').removeClass('open');
		}
		wwdev.Bootstrap.makeRequired();
		wwdev.Bootstrap.makeDisabled();
	});

	jQuery('.wizard .extraitem').on('click', function() {
		var max = jQuery(this).attr('data-max');
		var current = jQuery(this).attr('data-current');
		var parent = jQuery(this).attr('data-parent');
		current++;
		jQuery(this).attr('data-current', current);
		var k = '.' + parent + '-' + current;
		jQuery(k).removeClass('hide');
		jQuery(k).find('.component-list').addClass('open'); // open gezet voor MAILONLY
		if (jQuery('#productDetailWrapper .wrapper > div.left, #productDetailWrapper .wrapper > div.right').length > 0) {
			jQuery('#productDetailWrapper .wrapper > div.left, #productDetailWrapper .wrapper > div.right').setEqualHeightsPerRow();
		}
		if (current == max) {
			jQuery(this).hide();
		}
		wwdev.Bootstrap.makeDisabled();
		wwdev.Bootstrap.makeRequired();
		return false;
	});

	jQuery('.wizard input[type=radio]').on('click', function() {
		jQuery(this).closest('ul').find('.showwhenactive').removeClass('active');
		jQuery(this).closest('li').find('.showwhenactive').addClass('active');
		wwdev.Bootstrap.makeDisabled();
		wwdev.Bootstrap.makeRequired();
	});

	jQuery('.wizard ul.product-list input').on('change', function () {
		if (jQuery('#productDetailWrapper .wrapper > div.left, #productDetailWrapper .wrapper > div.right').length > 0) {
			jQuery('#productDetailWrapper .wrapper > div.left, #productDetailWrapper .wrapper > div.right').setEqualHeightsPerRow();
		}
		wwdev.Bootstrap.makeSidebarWizard();
	});
	jQuery('.close-productcounter').on('click', function() {
		var parent = jQuery(this).parent();
		var c = parent.attr('class').split(/\s+/);
		jQuery.each(c, function (key, value) {
			if (value.indexOf('element') >= 0) {
				var ind = '.' + value;
				jQuery(ind).addClass('hide');
			}
		});

		wwdev.Bootstrap.makeDisabled();
		wwdev.Bootstrap.makeRequired();
		return false;
	});
};

wwdev.Bootstrap.slide = function() {
	jQuery('.slide-open').on('change', function() {
		if (jQuery(this).is(':checked')) {
			jQuery(jQuery(this).attr('data-open')).show();
		} else {
			jQuery(jQuery(this).attr('data-open')).hide();
		}
		jQuery('div.newuser-data .contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
		wwdev.Bootstrap.makeRequired();
		wwdev.Bootstrap.makeDisabled();
	});
};

wwdev.Bootstrap.accordion = function() {
	jQuery('.accordion_button:not(.alwaysopen)').on('click',function() {
		jQuery(this).toggleClass('active', '');
		jQuery(this).find('.chev').toggleClass('south', 'north');
		jQuery(this).parent().find('.panel').toggle();
	});
};

wwdev.Bootstrap.other = function() {
	jQuery('.ideal_wrapper').on('click',function() {
		jQuery('.chev').toggleClass('modify_turn');
	});
	jQuery('.btn-normal.disabled').on('click', function() {
		if (typeof jQuery(this).data('popuptext') !== 'undefined') {
			alert(jQuery(this).data('popuptext'));
		}
		return false;
	});
};

wwdev.Bootstrap.menu = function() {
	jQuery('.menu_img').on('click',function() {
		jQuery('.menu_log-out').toggle();
		jQuery('.head').toggleClass('menu_out');
		jQuery('.navbar').toggleClass('menu_out');
		jQuery('.user_dropdown').toggleClass('reset');
		jQuery('.menu_img').toggleClass('menu_img_out');
	});
	jQuery('.small_menu_button').on('click',function(e) {
		e.preventDefault();
		jQuery('.small_menu').toggle();
	});
	jQuery('.selectLocation').on('change', function() {
		location.href=project_url + '/locatie-wijzigen/' + jQuery(this).val();
	});
};

wwdev.Bootstrap.customerView = function() {
	jQuery('.phone-selection .switch input[type=checkbox]').on('change', function() {
		jQuery('.phone-selection form').submit();
	});
};

wwdev.Bootstrap.zipcodeChecker = function() {
	jQuery('.zipcodechecker button').on('click', function() {
		var overlay = jQuery('.zipcodechecker .overlay');
		var zipcodefield = jQuery('.zipcodechecker input.zipCode');
		overlay.addClass('show');
		var zipcode = zipcodefield.val();
		if (zipcode !== '') {
			var zipcodeclean = zipcode.toUpperCase().replace(' ','').replace(' ','').replace(' ','').replace(' ','');
			if (zipcodeclean.match(/^[1-9]{1}[0-9]{3}[A-Z]{2}$/g)) {
				var postcode = zipcodeclean;
				var huisnr = jQuery('.zipcodechecker .houseNr').val();
				var huisnrext = jQuery('.zipcodechecker .houseNrExtension').val();
				var token = jQuery('.zipcodechecker input[name=_token]').val();
				var type = jQuery('.zipcodechecker input[name=type]').val();
				var url = jQuery('.zipcodechecker input[name=url]').val();
				var ServiceId = "";
				if (jQuery('.zipcodechecker input[name=ServiceId]').length > 0) {
					ServiceId = jQuery('.zipcodechecker input[name=ServiceId]').val();
				}
				var TelLinesharing = "";
				if (jQuery('.zipcodechecker input[name=TelLinesharing]').length > 0) {
					TelLinesharing = jQuery('.zipcodechecker input[name=TelLinesharing]').val();
				}
				if (postcode !== '' && huisnr !== '') {
					document.getElementById('loading').style.display = 'block';
					jQuery.ajax({
						url: url,
						method: 'POST',
						data: {'postcode': postcode, 'huisnr': huisnr, 'toev': huisnrext, '_token': token, 'type': type, 'ServiceId': ServiceId, 'TelLinesharing': TelLinesharing},
						async: true
					}).done(function (r) {
						if (r === 'OK') {
							jQuery('.zipcodechecker > .block > p').html('Uw postcode is gevonden.').removeClass('error');
							overlay.removeClass('show');
							window.location.href = window.location.href;
							return false;
						} else if (r === 'NO') {
							jQuery('.zipcodechecker > .block > p').html('Helaas zijn er op uw account geen pakketten gevonden of is de ingevuld postcode onjuist.').addClass('error');
							overlay.removeClass('show').hide();
						} else if (r.substr(0, 3) === 'NO|') {
							jQuery('.zipcodechecker > .block > p').html('Er is al een account bekend op dit adres.');
							overlay.removeClass('show');
							window.location.href = window.location.href;
							return false;
						} else {
							overlay.addClass('show');
						}
					});
				} else {
					jQuery('.zipcodechecker > .block > p').html('Helaas zijn er op uw account geen pakketten gevonden of is de ingevuld postcode onjuist.').addClass('error');
					overlay.removeClass('show');
				}
			} else {
				jQuery('.zipcodechecker > .block > p').html('Helaas zijn er op uw account geen pakketten gevonden of is de ingevuld postcode onjuist.').addClass('error');
				overlay.removeClass('show');
			}
		}
		overlay.removeClass('show');
		return false;
	});
	jQuery('a.stopzipcode').on('click', function() {
		jQuery('form.deletezipcode').submit();
		return false;
	});
};

wwdev.Bootstrap.confirms = function() {
	jQuery('a.confirm').on('click', function() {
		var msg = 'Weet u dit zeker?';
		if (jQuery(this).attr('confirm-msg')) {
			msg = jQuery(this).attr('confirm-msg');
		}
		if (confirm(msg)) {
			location.href = jQuery(this).attr('href');
		}
		return false;
	});

	jQuery('form.delete:not(.no-confirm) > a').on('click', function(e) {
		var msg = 'Weet u zeker dat u dit item wilt verwijderen?';
		if (jQuery(this).attr('data-text')) {
			msg = jQuery(this).attr('data-text');
		} else if (jQuery(this).parents('form').attr('data-text')) {
			msg = jQuery(this).parents('form').attr('data-text');
		}
		if (confirm(msg)) {
			jQuery(this).parent('form').submit();
		} else {
			e.stopImmediatePropagation();
		}
		return false;
	});

	jQuery('form.delete.no-confirm > a').on('click', function(e) {
		jQuery(this).parent('form').submit();
		return false;
	});
};

wwdev.Bootstrap.datepicking = function() {
	if (jQuery('.datepicker').length >= 1) {
		jQuery.datepicker.regional.nl = {
			closeText: "Sluiten",
			prevText: "←",
			nextText: "→",
			currentText: "Vandaag",
			monthNames: ["januari", "februari", "maart", "april", "mei", "juni",
				"juli", "augustus", "september", "oktober", "november", "december"],
			monthNamesShort: ["jan", "feb", "mrt", "apr", "mei", "jun",
				"jul", "aug", "sep", "okt", "nov", "dec"],
			dayNames: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
			dayNamesShort: ["zon", "maa", "din", "woe", "don", "vri", "zat"],
			dayNamesMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
			weekHeader: "Wk",
			dateFormat: "dd-mm-yy",
			defaultDate: "+1w",
			isRTL: false,
			showMonthAfterYear: false,
			yearSuffix: "",
			firstDay: 0
		};
		jQuery('.datepicker').each(function () {
			config = jQuery(this).data();
			var options = jQuery.extend(
				config,
				jQuery.datepicker.regional['nl']
			);
			if (jQuery(this).hasClass('datepicker-desired-date')) {
				options.beforeShowDay = function(date) {

					const datesAreOnSameDay = (first, second) =>
						first.getFullYear() === second.getFullYear() &&
						first.getMonth() === second.getMonth() &&
						first.getDate() === second.getDate();

					var _allowedDates = options.allowedDates.split(',');
					for (i in _allowedDates) {
						if (datesAreOnSameDay(date, new Date(_allowedDates[i]))) {
							return [true, ""];
						}
					}

					return [false, ""];
				}
			} else if (jQuery(this).hasClass('datepicker-desired-date-withweekend')) {
				options.minDate = 0;
			}
			if (jQuery(this).hasClass('datepicker-with-previous-dates')) {
				options.minDate = '-1Y';
			}
			jQuery(this).datepicker(options);
		});
	}
};

function enabledFilterTextListener() {
    enabledFilterText();
    jQuery('input[name=category]').on('change', function () {
        enabledFilterText();
    });
    jQuery('input[name=sitecontent]').on('change', function() {
        enabledFilterText();
    });
}

function enabledFilterText() {
	let categories = $('input[name=category]').is(':checked');
	let siteFilter = $('input[name=sitecontent]').is(':checked');

    $('.filter_settings_text').find('[class^=text-]').addClass('hide');

	if(categories === false && siteFilter === false) {
		$('.filter_settings_text .text-1').removeClass('hide');
	}

	if(categories === true && siteFilter === true) {
        $('.filter_settings_text .text-2').removeClass('hide');
	}

	if(categories === true && siteFilter === false) {
        $('.filter_settings_text .text-3').removeClass('hide');
	}

	if(categories === false && siteFilter === true) {
        $('.filter_settings_text .text-4').removeClass('hide');
	}
}

wwdev.Bootstrap.filters = function() {
	jQuery('input[name=category]').on('change', function () {
		let disabled = jQuery(this).is(':checked') === false;
		jQuery('input[name=category-all]').prop('disabled', disabled);

		if (disabled === true) {
			jQuery('.filter-table').addClass('disabled');
            jQuery('.filter-table').find('.switch input[type=checkbox]').addClass('active');
		} else {
			jQuery('.filter-table').removeClass('disabled');
            jQuery('.filter-table').find('.switch input[type=checkbox]').removeClass('active');
        }
	});

	jQuery('input[name=category-all]').on('change', function () {
		let checked = jQuery(this).is(':checked');

		$(this).closest('.filter-table').find('.category-filter input').each(function () {
			jQuery(this).prop('checked', checked);
		});
	});

	enabledFilterTextListener();

	jQuery('a.pop-up').on('click', function(e) {
		e.preventDefault();
		jQuery('.filter-popup').addClass('hide-popup');
		$(this).closest('.popup-holder').find('.filter-popup').removeClass('hide-popup');
	});

	jQuery('a.close-pop-up-button').on('click', function(e) {
		e.preventDefault();
		$(this).closest('.filter-popup').addClass('hide-popup');
	});

	if (jQuery('.main_filter').length > 0) {
		jQuery('.filter_item').each(function () {
			jQuery(this).on('change', 'input[type=checkbox]', function () {
				if (jQuery(this).is(':checked')) {
					var dep = jQuery(this).attr('data-dependencies');
					if (dep) {
						var objs = jQuery.parseJSON(dep);
						jQuery.each(objs, function (key, obj) {
							if (obj.actions.length > 0) {
								jQuery.each(obj.actions, function (key2, item) {
									if (item.id) {
										var checkboxfield = jQuery('.filter_item_' + item.id).find('input[type=checkbox]').first();
										if (item.value == true) {
											if (!checkboxfield.is(':checked')) {
												checkboxfield.parent().trigger('click');
											}
										}
									}
								});
							}
						});
					}
				}
			});
		});

		jQuery('label.switch input[type=checkbox]').on('change', function() {
			var txt = jQuery(this).attr('data-alert');
			if (txt) {
				alert(txt);
			}
		});

		jQuery('.https_filter_switchcontainer input[type=checkbox]').on('change', function() {
			if (jQuery(this).is(':checked')) {
				jQuery('.filter_options').removeClass('hide');
				jQuery('.slider-on').addClass('hide');
				jQuery('.slider-on-' + jQuery('.filter_options input[type=range]').val()).removeClass('hide');
			} else {
				jQuery('.filter_options').addClass('hide');
				jQuery('.slider-on').addClass('hide');
				jQuery('.slider-on-0').removeClass('hide');
			}
		});

		jQuery('.fullwidth .body:not(.sub)').on('click', function() {
			jQuery(this).toggleClass('show');
			var groupId = jQuery(this).data('group');
			jQuery('.fullwidth .body.sub-' + groupId).toggleClass('open');
		});

		jQuery('.fullwidth .body.cat .switch input').on('change', function(event) {
			event.stopPropagation();
			var cat = jQuery(this).closest('.body.cat');
			jQuery(this).removeClass('indeterminate');
			if (jQuery(this).is(':checked')) {
				if (!cat.hasClass('show')) {
					cat.trigger('click');
				}
				jQuery('.fullwidth .body.sub-' + cat.data('group') + ' .switch input:not(:checked)').parent().trigger('click');
				return false;
			} else {
				jQuery('.fullwidth .body.sub-' + cat.data('group') + ' .switch input:checked').parent().trigger('click');
				return false;
			}
		});
		jQuery('.fullwidth .head .switch input').on('change', function() {
			if (jQuery(this).is(':checked')) {
				jQuery('.fullwidth .body.cat .switch input:not(:checked)').parent().trigger('click');
			} else {
				jQuery('.fullwidth .body.cat .switch input:checked').parent().trigger('click');
			}
		});

		jQuery('.filter_container .subitem label.switch input[type=checkbox]').on('change', function() {
			var subitemcategory = jQuery(this).closest('.subitem').data('subitemcategory');
			var subitems = jQuery('.subitem-' + subitemcategory + ' input[type=checkbox]');
			if (subitems.length > 0) {
				category = null;
				subitems.each(function(key, value) {
					var checked = jQuery(value).prop('checked');
					if (checked === true) {
						checked = 1;
					} else {
						checked = 0;
					}
					if (category === null) {
						category = checked;
					} else if (category != checked) {
						category = 2;
					}
				});
				if (category == 1) {
					jQuery('.category.category-' + subitemcategory + ' .switch input[type=checkbox]').prop('checked', true).removeClass('indeterminate');
				} else if (category == 2) {
					jQuery('.category.category-' + subitemcategory + ' .switch input[type=checkbox]').prop('checked', false).addClass('indeterminate');
				} else {
					jQuery('.category.category-' + subitemcategory + ' .switch input[type=checkbox]').prop('checked', false).removeClass('indeterminate');
				}
			}
		});

		jQuery('.filter_container .category').on('click', function(e) {
			if (jQuery(e.target).prop("tagName") === 'INPUT' || jQuery(e.target).hasClass('slider') || jQuery(e.target).hasClass('switch')) {
				e.stopPropagation();
				var cat = jQuery(e.target).closest('.category').data('subitemcategory');
				if (jQuery(e.target).hasClass('switch')) {
					jQuery(e.target).find('input[type=checkbox]').removeClass('indeterminate');
					setTimeout(function() {
						jQuery('.subitem-' + cat).removeClass('hide').find('input[type=checkbox]').prop('checked', jQuery(e.target).find('input[type=checkbox]').prop('checked'));
					}, 300);
				}
				if (jQuery(e.target).hasClass('slider')) {
					jQuery(e.target).parent().find('input[type=checkbox]').removeClass('indeterminate');
					setTimeout(function() {
						jQuery('.subitem-' + cat).removeClass('hide').find('input[type=checkbox]').prop('checked', jQuery(e.target).parent().find('input[type=checkbox]').prop('checked'));
					}, 300);
				}
				return;
			}
			var categorytitle = jQuery(this).data('subitemcategory');
			jQuery('.subitem-' + categorytitle).toggleClass('hide');
		});

		jQuery('.item_description').on('click', function() {
			var dataText = jQuery(this).data('text');
			var htmlText = jQuery(this).html();
			jQuery(this).html(dataText);
			jQuery(this).data('text', htmlText);

            $(this).closest('.filter_item').find('.open-item-description i').toggleClass('fa-chevron-down');
            $(this).closest('.filter_item').find('.open-item-description i').toggleClass('fa-chevron-up');
		});

		jQuery('.open-item-description').on('click', function(e) {
			e.preventDefault();
			$(this).closest('.filter_item').find('.item_description').click();

		})
	}
};

wwdev.Bootstrap.infoOptions = function() {
	jQuery('.msg-info').on('click', function(e) {
		var msg = jQuery(this).parent().find('.msg');
		var left = jQuery(this).position().left;
		var padding = 43;
		if (jQuery(this).data('min-padding')) {
			padding = jQuery(this).data('min-padding');
		}
		msg.show();
		msg.find('.arrowborder').css('left', 'calc(' + left + 'px - ' + padding + 'px)');

		e.stopPropagation();
		e.preventDefault();
		return false;
	});

	jQuery('.msg .cross').on('click', function() {
		jQuery(this).closest('.msg').hide();
	});
};

wwdev.Bootstrap.makeSidebarWizard = function () {
	if (jQuery('.rightside').length > 0) {
		var counter = {};
		var namesVisited = {};
		jQuery('.wizard ul.product-list input').each(function () {
			var input = jQuery(this);
			var prodId = jQuery(this).attr('data-productid');
			if (!counter[prodId]) {
				counter[prodId] = 0;
			}
			if (input.attr('type') === 'radio') {
				var name = input.attr('name');
				var prodId = jQuery('input[name="' + name + '"]:checked').val();

				if (!namesVisited[name]) {
					namesVisited[name] = 1;
					if (!counter[prodId]) {
						counter[prodId] = 0;
					}
					if (prodId) {
						if (input.closest('ul').is(':visible')) {
							counter[prodId]++;
						}
					}
				}
			} else if (input.attr('type') === 'checkbox') {
				jQuery('.paymentcontainer').each(function () {
					var tr = jQuery(this).find('tr.product-' + prodId).first();
					if (tr.length > 0) {
						if (input.is(':checked') && input.closest('ul').is(':visible')) {
							counter[prodId]++;
						}
					}
				});
			}
		});
		if (jQuery('.wizard ul.product-list input').length > 0) {
			jQuery('.paymentcontainer').each(function () {
				jQuery(this).find('tr').addClass('hide');
			});

			var log = {};
			jQuery.each(counter, function (key, value) {
				if (value > 0) {
					log[key] = value;
					var tr = jQuery('tr.product-' + key);
					tr.removeClass('hide');
					tr.find('td:first-child').html(value + ' x ' + tr.attr('data-name'));
					tr.find('td:last-child strong').html('&euro;&nbsp;' + numberToPrice(value * tr.attr('data-price')));
				}
			});

			jQuery('.paymentcontainer').each(function () {
				var hide = true;
				jQuery(this).find('tr').each(function () {
					if (!jQuery(this).hasClass('hide')) {
						hide = false;
					}
				});
				jQuery(this).removeClass('hide');
				if (hide === true) {
					jQuery(this).addClass('hide');
				}
			});
		}
	}
};

wwdev.Bootstrap.unsaved = function() {
	var unsaved = false;
	jQuery('.details-modify input').on('change', function() {
		unsaved = true;
	});
	jQuery('.details-modify form').submit(function() {
		unsaved = false;
		return true;
	});

	window.onbeforeunload = function() {
		if (unsaved === true) {
			if (confirm('U heeft wijzigen gedaan zonder op te slaan. Wilt u deze pagina toch verlaten?')) {
				return true;
			}
			return false;
		}
	};
};

// check iban
wwdev.Bootstrap.ibanbutton = function() {
	var ibanbtn = jQuery('.ibanbtn');
	if (ibanbtn.length > 0) {
		var ibanfield = jQuery('#' + ibanbtn.attr('ibanTextfieldId'));
		if (ibanfield) {
			ibanfield.on('blur, change', function () {
				var v = (jQuery(this).val()).replace(/\s/g, '');
				jQuery(this).val(v);
				var ibanspecial = jQuery('.iban-special');
				if (v.match(/^[a-z]{2}[0-9]{2}([^0-9]+).+$/gi)) {//NL91 ABNA 041 7164300
					v.replace(/^[a-z]{2}[0-9]{2}([^0-9]+).+$/gi, function (fm, m1) {
						if (1==2 && (m1 === 'ABNA' || m1 === 'RABO' || m1 === 'DEUT' || m1 === 'INGB' || m1 === 'BNGH')) {
							ibanbtn.text('Verstuur en geef online doorlopende machtiging');
							var txt = '<div class="text">' + ibanspecial.attr('data-iban-special-text') + '</div>';
							var lgo = ibanspecial.attr('data-iban-special-logo');
							if (lgo) {
								txt = '<img src="' + lgo + '" class="iban-special-logo" />' + txt;
							}
							ibanspecial.html(txt);
						} else {
							ibanbtn.html(ibanbtn.attr('original-text'));
							ibanspecial.html('');
						}
					});
				} else {
					ibanbtn.html(ibanbtn.attr('original-text'));
					ibanspecial.html('');
				}
			});
			ibanfield.trigger('change');
		}




		jQuery('#iban, #accountholder').on('change', function() {
			var iban = jQuery('#iban');
			var accountholder = jQuery('#accountholder');
			if (iban.val() == iban.attr('original-value') && accountholder.val() == accountholder.attr('original-value')) {
				ibanbtn.attr('disabled', 'disabled');
				ibanbtn.addClass('disabled');
			} else {
				ibanbtn.attr('disabled', false);
				ibanbtn.removeClass('disabled');
			}
		});
	}
};

wwdev.Bootstrap.ajax = function() {
	if(jQuery('.partners .address.clea').length > 0) {
		jQuery('.partners .accordion_button').on('click', function() {
			if (jQuery(this).hasClass('active')) {
				var address = jQuery(this).parent().find('.address.clea');
				if (address.length> 0 && address.attr('route')) {
					$.ajax({
						method: "GET",
						url: address.attr('route'),
						cache: false
					}).done(function(r) {
						address.parent().html(r);
					});
				}
			}
		});
	}
};

wwdev.Bootstrap.responsive = function() {
	if (jQuery(window).width() < 768) {
		if (jQuery('.wrapper_details .title.accordion_button').length > 0) {
			jQuery('.wrapper_details .title.accordion_button').first().trigger('click');
		}
	}
};
var loadingSoBlockingF5 = false;

wwdev.Bootstrap.buttons = function() {
	jQuery('.btn-loadable').on('click', function() {
		if (jQuery(this).closest('.add_email').length > 0 && jQuery(this).closest('.add_email').find('label.error:visible').length > 0) {
		} else {
			if (jQuery(this).hasClass('btn-loading')) {
				return false;
			} else {
				jQuery(this).addClass('btn-loading');
				jQuery('.loading-big').addClass('show');
				jQuery('body').addClass('noscroll');
				var self = this;
			}
		}
	});
};

wwdev.Bootstrap.loader = function(form, element) {
	if (element && element.length > 0) {
		if (element.hasClass('btn-loading')) {
			return false;
		} else {
			element.addClass('btn-loading');
			jQuery('.loading-big').addClass('show');
			jQuery('body').addClass('noscroll');
			form.submit();
		}
	} else {
		form.submit();
	}
};

wwdev.Bootstrap.fabian = function() {
	jQuery('.startdatediv input[name=optiondate]').on('change', function() {
		if (jQuery(this).val() !== 'date') {
			return;
		}
		wwdev.Bootstrap.makeAjaxCall();
	});

	jQuery('#newdate-new-address').on('change', function() {
		wwdev.Bootstrap.makeAjaxCall();
	});
}

wwdev.Bootstrap.makeAjaxCall = function () {
	jQuery.get('/allowed-date', { 'preferred-date': jQuery('#newdate-new-address').val() }).done(function (response){
		let $oldDate = jQuery('#olddate-old-adress');
		$oldDate.val(response['min-date']);
		$oldDate.data('min-date', response['min-date']);
		let config = $oldDate.data();
		config = jQuery.extend(
			config,
			jQuery.datepicker.regional['nl']
		);
		$oldDate.datepicker(config);
	});
}

wwdev.Bootstrap.startdatediv = function() {
	var self = this;
	jQuery('.startdatediv input[name=optiondate]').on('change', function() {
		var newdate = jQuery('#newdate');
		if (newdate.length > 0) {
			config = jQuery(newdate).data();
			var options_newdate = jQuery.extend(
				config,
				jQuery.datepicker.regional['nl']
			);
			options_newdate.beforeShowDay = jQuery.datepicker.noWeekends;
			options_newdate.minDate = 0;
			var date1 = new Date(jQuery.datepicker.formatDate('yy-mm-dd', new Date()));
			var date2 = new Date(newdate.attr('data-min-date'));
			var numdays = Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
			options_newdate.minDate = numdays;
			options_newdate.maxDate = 30 * 3;

			if (newdate.attr('data-max-date')) {
				var currentdate = (new Date()).getTime();
				var maxdate = (new Date(newdate.attr('data-max-date'))).getTime();
				var msTillMaxDate = Math.round(maxdate-currentdate);
				options_newdate.maxDate = msTillMaxDate/(1000*60*60*24);
			}

			newdate.datepicker('destroy');
			newdate.val('');
			newdate.datepicker(options_newdate);
		}

		var enddate = jQuery('#olddate');
		if (enddate.length > 0) {
			config = jQuery(enddate).data();
			var options = jQuery.extend(
				config,
				jQuery.datepicker.regional['nl']
			);
			options.beforeShowDay = jQuery.datepicker.noWeekends;
			options.minDate = 0;
			var date1 = new Date(jQuery.datepicker.formatDate('yy-mm-dd', new Date()));
			var date2 = new Date(enddate.attr('data-min-date'));
			var numdays = Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
			options.minDate = numdays;
			options.maxDate = 30 * 3;
		}

		if (jQuery(this).val() === 'date') {
			jQuery('#desiredStartDateDiv').show();

			if (jQuery('#opheftext').length > 0) {
				jQuery('#opheftext').hide();
			}

			if (enddate.length > 0) {
				enddate.datepicker('destroy');
				enddate.val('');
				enddate.datepicker(options);
			}
		} else {
			jQuery('#desiredStartDateDiv').hide();
			if (jQuery('#opheftext').length > 0) {
				jQuery('#opheftext').show();
			}

			if (enddate.length > 0) {
				enddate.prop('data-min-date', enddate.data('min-date-6'));
				enddate.attr('data-min-date', enddate.data('min-date-6'));
				enddate.datepicker('destroy');
				var date2 = new Date(enddate.attr('data-min-date'));
				var numdays = Math.round((date2-date1)/(1000*60*60*24));
				options.minDate = numdays;
				enddate.val('');
				enddate.datepicker(options);
			}
		}
		if (jQuery('.contract_box.column-50 .box_content').length > 0) {
			jQuery('.contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
		}
		self.makeDisabled();
		self.makeRequired();
		wwdev.Bootstrap.datepicking();
	});
	jQuery('#desiredStartDateDiv').hide();
	jQuery('#opheftext').hide();
};

wwdev.Bootstrap.emailcheck = function() {
	jQuery('.onlyemailtypes').on('keyup', function() {
		jQuery(this).val((jQuery(this).val()).replace(/[^a-zA-Z0-9-_\.\@]/g, ''));
	});
	jQuery('.onlyemailtypes').on('change', function() {
		jQuery(this).val((jQuery(this).val()).replace(/[^a-zA-Z0-9-_\.\@]/g, ''));
	});
};

wwdev.Bootstrap.employeepartner = function() {
	jQuery('.employee-partner .mainselector input[type=radio]').on('change', function() {
		var value = jQuery(this).val();
		if (value === 'new') {
			jQuery('.formfields').hide();
			jQuery('.new').show();
		} else {
			jQuery('.formfields').show();
			jQuery('.new').hide();
			jQuery('.startdate, .enddate').show();
			if (value === 'add') {
				// einddatum weg
				jQuery('.enddate').hide();
			} else if (value === 'end') {
				jQuery('.startdate').hide();
			}
		}
	});
};

wwdev.Bootstrap.newCustomer = function() {
	var self = this;

	jQuery('input[name=phone]').on('change', function() {
		if (jQuery(this).val() != 0) {
			jQuery('.phonebundle').show();
			jQuery('.keepphone').show();
		} else {
			jQuery('.phonebundle').hide();
			jQuery('.keepphone').hide();
		}
		self.makeDisabled();
		self.makeRequired();
	});

	jQuery('input[name=keepphone]').on('change', function() {
		if (jQuery(this).val() == 1) {
			jQuery('.hide-keepphone-number').show();
		} else {
			jQuery('.hide-keepphone-number').hide();
		}
		self.makeDisabled();
		self.makeRequired();
	});

	jQuery('#bussiness').on('change', function() {
		var postaladdresscontainer = jQuery('.bussiness-div');
		if (jQuery(this).is(':checked')) {
			postaladdresscontainer.removeClass('hide');
		} else {
			postaladdresscontainer.addClass('hide');
		}
		self.makeDisabled();
		self.makeRequired();
		jQuery('.bussiness-container .box_content').css('height', 'auto');
	});

	jQuery('input[name=different_address]').on('change', function() {
		if (jQuery('div.newuser-data .contract_box.column-50 .box_content').length > 0) {
			jQuery('div.newuser-data .contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
			jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
		}
	});
};

wwdev.Bootstrap.kvk = function() {
	jQuery('#kvk').on('change', function() {
		if (isKvKNumber(jQuery(this).val())) {
			jQuery.ajax({
				url: "/ajax/kvk",
				data: {'kvk': jQuery(this).val()},
				async: true
			}).done(function (r) {
				var json = jQuery.parseJSON(r);
				if (!json.error) {
					var optionslist = '';
					jQuery('input.kvk-name').val(json.companyname);
					jQuery('span.kvk-name').html(json.companyname);
					jQuery.each(json.branches, function (index, value) {
						let huisnummer = value.address.huisnummer;
						let straatnaam = value.address.straatnaam;
						if (huisnummer === undefined) {
							huisnummer = value.address.postbusnummer;
							straatnaam = 'Postbus';
						}
						let postcode = value.address.postcode;
						let city = value.address.plaats;
						let huisnummerToevoeging = value.address.huisnummerToevoeging;
						if (huisnummerToevoeging === undefined) {
							huisnummerToevoeging = '';
						}
						optionslist = optionslist + '<option value="' + value.eersteHandelsnaam + '" data-street="' + straatnaam + '" data-housenr="' + huisnummer + '"  data-housenradd="' + huisnummerToevoeging + '"  data-postcode="' + postcode + '"  data-city="' + city + '">' + value.address.volledigAdres + '</option>';
					});
					jQuery('#location').html(optionslist).trigger('change');
				} else {
					jQuery('input.kvk-name').val('Onbekend');
					jQuery('span.kvk-name').html('Onbekend');
					jQuery('#location').html('');
				}
				if (jQuery('div.newuser-data .contract_box.column-50 .box_content').length > 0) {
					jQuery('div.newuser-data .contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
					jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
				}
			});
		}
	});
	jQuery('#location').on('change', function() {
		var datafield = jQuery(this).find('option:selected');
		jQuery('.kvk-street').html(datafield.data('street'));
		jQuery('input[name=kvk-street]').val(datafield.data('street'));

		jQuery('.kvk-housenr').html(datafield.data('housenr'));
		jQuery('input[name=kvk-housenr]').val(datafield.data('housenr'));

		jQuery('.kvk-housenradd').html(datafield.data('housenradd'));
		jQuery('input[name=kvk-housenradd]').val(datafield.data('housenradd'));

		jQuery('.kvk-postcode').html(datafield.data('postcode'));
		jQuery('input[name=kvk-postcode]').val(datafield.data('postcode'));

		jQuery('.kvk-city').html(datafield.data('city'));
		jQuery('input[name=kvk-city]').val(datafield.data('city'));
	});

	jQuery('.newuser-data').on('change', 'input[name=bussiness]', function() {
		if (jQuery('#FacturatieE-mailadres').val() == '') {
			jQuery('#FacturatieE-mailadres').val(jQuery('#email_contact').val());
		}
		if (jQuery('div.newuser-data .contract_box.column-50 .box_content').length > 0) {
			jQuery('div.newuser-data .contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
			jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
		}
	});

	jQuery('input#kvk').rules('add', {
		kvk: true
	});

	this.adultcheck();
};

wwdev.Bootstrap.adultcheck = function() {
	jQuery('#birth-year').rules('add', {
		isadult: true
	});
	jQuery('#birth-month, #birth-day').on('change', function() {
		jQuery('#birth-year').trigger('change');
		jQuery('#birth-year').valid();
		if (jQuery('div.newuser-data .contract_box.column-50 .box_content').length > 0) {
			setTimeout(function() {
				jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
				jQuery('div.newuser-data .contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
				jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
			}, 300);
		}
	});
};

wwdev.Bootstrap.postcode2 = function() {
	jQuery('#postal-postcode, #postal-number, #postal-numberExtra').on('change', function() {
		var postcode = jQuery('#postal-postcode').val();
		var number = jQuery('#postal-number').val();
		var numberExtra = jQuery('#ppostal-numberExtra').val();

		if (postcode && number) {
			jQuery.ajax({
				url: "/ajax/zipcode-address",
				data: {'postcode': postcode, 'huisnr': number, 'toev': numberExtra},
				async: true
			}).done(function (r) {
				var json = jQuery.parseJSON(r);
				if (jQuery(json.street)) {
					jQuery('#postal-street').val(json.street).attr('disabled', false).removeClass('disabled');
					jQuery('#postal-city').val(json.city).attr('disabled', false).removeClass('disabled');
				} else {
					jQuery('#postal-street').attr('disabled', false).removeClass('disabled');
					jQuery('#postal-city').attr('disabled', false).removeClass('disabled');
				}
			});
		}
	});

	jQuery('input.zipcode2').rules('add', {
		zipcode2: true
	});
};

wwdev.Bootstrap.olddate_newdate = function() {
	jQuery('#olddate').on('change', function() {
		var e = (jQuery(this).val()).split('-');
		var date1 = new Date(jQuery.datepicker.formatDate('yy-mm-dd', new Date(e[2] + '-' + e[1] + '-' + e[0])));
		var date2 = new Date(jQuery(this).attr('data-min-date'));
		var numdays = Math.round((date2-date1)/(1000*60*60*24));
		if (numdays > 0) {
			jQuery(this).val('');
		}
	});

	jQuery('#newdate').on('change', function() {
		var e = (jQuery(this).val()).split('-');
		var date1 = new Date(jQuery.datepicker.formatDate('yy-mm-dd', new Date(e[2] + '-' + e[1] + '-' + e[0])));
		var date2 = new Date(jQuery(this).attr('data-min-date'));
		var numdays = Math.round((date2-date1)/(1000*60*60*24));
		if (numdays > 0) {
			jQuery(this).val('');
		}
	});

	jQuery('#desiredStartDate').on('change', function() {
		var e = (jQuery(this).val()).split('-');
		var numdays = 0;
		if (e.length >= 2) {
			var date1 = new Date(jQuery.datepicker.formatDate('yy-mm-dd', new Date(e[2] + '-' + e[1] + '-' + e[0])));
			if (!jQuery(this).attr('data-min-date')) {
				var date2 = new Date(jQuery(this).attr('data-min-date'));
			} else {
				var date2 = new Date('01-01-2021');
			}
			numdays = Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
		} else {
			numdays = 1;
		}
		if (numdays > 0) {
			jQuery(this).val('');
		}

	});
};

wwdev.Bootstrap.newmessages = function() {
    jQuery.ajax({
        url: '/ajax/newmessages',
        method: 'GET',
    }).done(function (r) {
        r = parseInt(r);
        if (r > 0) {
            var messageImageHtml = jQuery('.envelop-messages').first();
            var currentImage = messageImageHtml.attr('src');
            currentImage = currentImage.replace('.svg', '_new.svg');
            messageImageHtml.attr('src', currentImage);
			messageImageHtml.addClass('new');

			messageImageHtml = jQuery('.envelop-messages').last();
			currentImage = messageImageHtml.attr('src');
			currentImage = currentImage.replace('.png', '-mark.png');
			messageImageHtml.attr('src', currentImage);
        }
    });
};

wwdev.Bootstrap.hidephonemenuwhenneeded = function() {
	if (jQuery('.sessie-non-internet').length <= 0) {
		jQuery.ajax({
			url: '/ajax/isNonInternet',
			method: 'GET',
		}).done(function (r) {
			if (r === 'OK') {
				jQuery('.hide-when-non-internet').hide();
			}
		});
	}
};

wwdev.Bootstrap.cdr = function() {
	jQuery('.cdr select.phonenumber').on('change', function() {
		jQuery(this).closest('form').submit();
	});
};

wwdev.Bootstrap.datatables = function() {
	$('.ajax-datatable').each(function() {
		jQuery(this).DataTable({
			"ajax": jQuery(this).data('url'),
			"lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "Alle"]],
			"fnDrawCallback": function(oSettings) {
				let displayLength = oSettings._iDisplayLength;
				if (displayLength > oSettings.fnRecordsDisplay() || displayLength < 0) {
					$(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
				} else {
					$(oSettings.nTableWrapper).find('.dataTables_paginate').show();
				}
			},
			"dataSrc": "data",
			"processing": true,
			"serverSide": true,
			"search": false,
			"bFilter": false,
			"columns": [
				{
					'data': 'Gesprekstype',
					'searchable': false
				},{
					'data': 'Ontvanger',
					'searchable': false
				},{
					'data': 'Datum',
					'searchable': false
				},{
					'data': 'Tijd',
					'searchable': false,
					'sortable': false
				},{
					'data': 'Duur',
					'searchable': false
				},{
					'data': 'Kosten',
					'searchable': false,
					'className': 'dt-right  text-right'
				}
			],
			language: {
				url: '/js/language.datatables.json'
			},
			"footerCallback": function ( row, data, start, end, display ) {
				var api = this.api(), data;
				let footerData = this.api().ajax.json();
				$(api.column(4).footer()).html(footerData.durationTotal);
				$(api.column(5).footer()).html(footerData.costsTotal);

			}
		});
	});
};

wwdev.Bootstrap.run = function () {
	this._run();
	this.validating();
	this.datepicking();
	this.wizard();
	this.slide();
	this.accordion();
	this.other();
	this.menu();
	this.customerView();
	this.zipcodeChecker();
	this.confirms();
	this.filters();
	this.makeSidebarWizard();
	this.ibanbutton();
	this.ajax();
	this.fabian();
	jQuery('form').each(function(index, element) {
		jQuery(element).validate({
			invalidHandler: function(form, validator) {
				if (jQuery('div.newuser-data .contract_box.column-50 .box_content').length > 0) {
					setTimeout(function() {
						jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
						jQuery('div.newuser-data .contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
						jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
					}, 300);
				}
			},
			submitHandler: function(form) {
				wwdev.Bootstrap.loader(form, jQuery(form).find('button.btn-loadable'));
			}
		});
	});
	jQuery('.link-loadable').on('click', function() {
		var element = jQuery(this);
		if (!element.hasClass('link-loading')) {
			element.addClass('link-loading');
			var rememberOldHTML = jQuery('.loading-big .showing').html();
			jQuery('.loading-big .showing').html('De pagina wordt geladen. Één moment a.u.b. <div class="image"><img src="/img/tenor.gif"></div>');
			jQuery('.loading-big').addClass('show');
			jQuery('body').addClass('noscroll');
		}
	});
	this.unsaved();
	this.responsive();
	this.startdatediv();
	this.infoOptions();
	this.emailcheck();
	this.employeepartner();
	this.newCustomer();
	this.adultcheck();
	this.kvk();
	this.postcode2();
	this.olddate_newdate();
	this.newmessages();
	this.hidephonemenuwhenneeded();
	this.cdr();
	this.datatables();
};

jQuery(document).ready(function(){
	wwdev.Bootstrap.runOnReady();

	jQuery('.submit-button-main-functionality').on('click', function(event) {
		event.stopImmediatePropagation();
		jQuery(this).stop();
		alert('Deze functionaliteit bestaat nog niet');
		return false;
	});

	if (jQuery('.iban input').length > 0) {
		jQuery('.iban input').rules('add', {
			iban: true
		});
	}
    jQuery('form.prevent-multiple-submit').submit(function () {
        $(this).find(':button').prop('disabled', true);
        return false;

    })

});

jQuery(window).on('load',function(){
	wwdev.Bootstrap.runOnLoad();
	if (jQuery('div.productwrapper section.productBundle .productImageWrap').length > 0) {
		jQuery('div.productwrapper section.productBundle .productImageWrap').setEqualHeightsPerRow({'heighttype': 'inner'});
	}
	if (jQuery('div.productwrapper section.productBundle').length > 0) {
		jQuery('div.productwrapper section.productBundle').setEqualHeightsPerRow({'heighttype': 'inner'});
	}
	if (jQuery('div.newuser-data .contract_box.column-50 .box_content').length > 0) {
		jQuery('div.newuser-data .contract_box.column-50').setEqualHeightsPerRow({'heighttype': 'inner'});
		jQuery('div.newuser-data .contract_box.column-50 .box_content').setEqualHeightsPerRow({'heighttype': 'inner'});
	}
	if (jQuery('.navbar .head .head_items .head_menu_items .item').length > 0) {
		jQuery('.navbar .head .head_items .head_menu_items .item').setEqualHeightsPerRow();
	}
});
