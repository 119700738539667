function gotoLogin() {
	if (jQuery('input[name=username]:visible').length > 0) {
		$('html, body').animate({
			scrollTop: jQuery('input[name=username]').offset().top
		}, 250);
		jQuery('input[name=username]').focus();
	} else if (jQuery('a[href="#loginwindow"]').length > 0 && jQuery('a[href="#loginwindow"]').hasClass('fancybox')) {
		jQuery('a[href="#loginwindow"]').trigger('click');
	} else if (jQuery('a.fancybox_iframe_login').length > 0) {
		jQuery('a.fancybox_iframe_login').trigger('click');
	} else if (loginPageSeoFormatUrl) {
		location.href=loginPageSeoFormatUrl;
	}
}

jQuery.validator.addMethod( "iban", function( value, element ) {

	// Some quick simple tests to prevent needless work
	if ( this.optional( element ) ) {
		return true;
	}

	// Remove spaces and to upper case
	var iban = value.replace( / /g, "" ).toUpperCase(),
		ibancheckdigits = "",
		leadingZeroes = true,
		cRest = "",
		cOperator = "",
		countrycode, ibancheck, charAt, cChar, bbanpattern, bbancountrypatterns, ibanregexp, i, p;

	// Check for IBAN code length.
	// It contains:
	// country code ISO 3166-1 - two letters,
	// two check digits,
	// Basic Bank Account Number (BBAN) - up to 30 chars
	var minimalIBANlength = 5;
	if ( iban.length < minimalIBANlength ) {
		return false;
	}

	// Check the country code and find the country specific format
	countrycode = iban.substring( 0, 2 );
	bbancountrypatterns = {
		"AL": "\\d{8}[\\dA-Z]{16}",
		"AD": "\\d{8}[\\dA-Z]{12}",
		"AT": "\\d{16}",
		"AZ": "[\\dA-Z]{4}\\d{20}",
		"BE": "\\d{12}",
		"BH": "[A-Z]{4}[\\dA-Z]{14}",
		"BA": "\\d{16}",
		"BR": "\\d{23}[A-Z][\\dA-Z]",
		"BG": "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
		"CR": "\\d{17}",
		"HR": "\\d{17}",
		"CY": "\\d{8}[\\dA-Z]{16}",
		"CZ": "\\d{20}",
		"DK": "\\d{14}",
		"DO": "[A-Z]{4}\\d{20}",
		"EE": "\\d{16}",
		"FO": "\\d{14}",
		"FI": "\\d{14}",
		"FR": "\\d{10}[\\dA-Z]{11}\\d{2}",
		"GE": "[\\dA-Z]{2}\\d{16}",
		"DE": "\\d{18}",
		"GI": "[A-Z]{4}[\\dA-Z]{15}",
		"GR": "\\d{7}[\\dA-Z]{16}",
		"GL": "\\d{14}",
		"GT": "[\\dA-Z]{4}[\\dA-Z]{20}",
		"HU": "\\d{24}",
		"IS": "\\d{22}",
		"IE": "[\\dA-Z]{4}\\d{14}",
		"IL": "\\d{19}",
		"IT": "[A-Z]\\d{10}[\\dA-Z]{12}",
		"KZ": "\\d{3}[\\dA-Z]{13}",
		"KW": "[A-Z]{4}[\\dA-Z]{22}",
		"LV": "[A-Z]{4}[\\dA-Z]{13}",
		"LB": "\\d{4}[\\dA-Z]{20}",
		"LI": "\\d{5}[\\dA-Z]{12}",
		"LT": "\\d{16}",
		"LU": "\\d{3}[\\dA-Z]{13}",
		"MK": "\\d{3}[\\dA-Z]{10}\\d{2}",
		"MT": "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
		"MR": "\\d{23}",
		"MU": "[A-Z]{4}\\d{19}[A-Z]{3}",
		"MC": "\\d{10}[\\dA-Z]{11}\\d{2}",
		"MD": "[\\dA-Z]{2}\\d{18}",
		"ME": "\\d{18}",
		"NL": "[A-Z]{4}\\d{10}",
		"NO": "\\d{11}",
		"PK": "[\\dA-Z]{4}\\d{16}",
		"PS": "[\\dA-Z]{4}\\d{21}",
		"PL": "\\d{24}",
		"PT": "\\d{21}",
		"RO": "[A-Z]{4}[\\dA-Z]{16}",
		"SM": "[A-Z]\\d{10}[\\dA-Z]{12}",
		"SA": "\\d{2}[\\dA-Z]{18}",
		"RS": "\\d{18}",
		"SK": "\\d{20}",
		"SI": "\\d{15}",
		"ES": "\\d{20}",
		"SE": "\\d{20}",
		"CH": "\\d{5}[\\dA-Z]{12}",
		"TN": "\\d{20}",
		"TR": "\\d{5}[\\dA-Z]{17}",
		"AE": "\\d{3}\\d{16}",
		"GB": "[A-Z]{4}\\d{14}",
		"VG": "[\\dA-Z]{4}\\d{16}"
	};

	bbanpattern = bbancountrypatterns[ countrycode ];

	// As new countries will start using IBAN in the
	// future, we only check if the countrycode is known.
	// This prevents false negatives, while almost all
	// false positives introduced by this, will be caught
	// by the checksum validation below anyway.
	// Strict checking should return FALSE for unknown
	// countries.
	if ( typeof bbanpattern !== "undefined" ) {
		ibanregexp = new RegExp( "^[A-Z]{2}\\d{2}" + bbanpattern + "$", "" );
		if ( !( ibanregexp.test( iban ) ) ) {
			return false; // Invalid country specific format
		}
	}

	// Now check the checksum, first convert to digits
	ibancheck = iban.substring( 4, iban.length ) + iban.substring( 0, 4 );
	for ( i = 0; i < ibancheck.length; i++ ) {
		charAt = ibancheck.charAt( i );
		if ( charAt !== "0" ) {
			leadingZeroes = false;
		}
		if ( !leadingZeroes ) {
			ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf( charAt );
		}
	}

	// Calculate the result of: ibancheckdigits % 97
	for ( p = 0; p < ibancheckdigits.length; p++ ) {
		cChar = ibancheckdigits.charAt( p );
		cOperator = "" + cRest + "" + cChar;
		cRest = cOperator % 97;
	}
	return cRest === 1;
}, "Vul een geldige IBAN in" );

if (typeof activeLanguage != 'undefined' && activeLanguage != 'nl' && activeLanguage != null) {
    console.log("activeLanguage" + activeLanguage);    
    if (activeLanguage == 'de') {
        $.extend( $.validator.messages, {
        	required: "Dieses Feld ist ein Pflichtfeld.",
        	maxlength: $.validator.format( "Geben Sie bitte maximal {0} Zeichen ein." ),
        	minlength: $.validator.format( "Geben Sie bitte mindestens {0} Zeichen ein." ),
        	rangelength: $.validator.format( "Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein." ),
        	email: "Geben Sie bitte eine gültige E-Mail-Adresse ein.",
        	url: "Geben Sie bitte eine gültige URL ein.",
        	date: "Geben Sie bitte ein gültiges Datum ein.",
        	number: "Geben Sie bitte eine Nummer ein.",
        	digits: "Geben Sie bitte nur Ziffern ein.",
        	equalTo: "Wiederholen Sie bitte denselben Wert.",
        	range: $.validator.format( "Geben Sie bitte einen Wert zwischen {0} und {1} ein." ),
        	max: $.validator.format( "Geben Sie bitte einen Wert kleiner oder gleich {0} ein." ),
        	min: $.validator.format( "Geben Sie bitte einen Wert größer oder gleich {0} ein." ),
        	creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein.",
        	remote: "Korrigieren Sie bitte dieses Feld.",
        	dateISO: "Geben Sie bitte ein gültiges Datum ein (ISO-Format).",
        	step: $.validator.format( "Geben Sie bitte ein Vielfaches von {0} ein." ),
        	maxWords: $.validator.format( "Geben Sie bitte {0} Wörter oder weniger ein." ),
        	minWords: $.validator.format( "Geben Sie bitte mindestens {0} Wörter ein." ),
        	rangeWords: $.validator.format( "Geben Sie bitte zwischen {0} und {1} Wörtern ein." ),
        	accept: "Geben Sie bitte einen Wert mit einem gültigen MIME-Typ ein.",
        	alphanumeric: "Geben Sie bitte nur Buchstaben (keine Umlaute), Zahlen oder Unterstriche ein.",
        	bankaccountNL: "Geben Sie bitte eine gültige Kontonummer ein.",
        	bankorgiroaccountNL: "Geben Sie bitte eine gültige Bank- oder Girokontonummer ein.",
        	bic: "Geben Sie bitte einen gültigen BIC-Code ein.",
        	cifES: "Geben Sie bitte eine gültige CIF-Nummer ein.",
        	cpfBR: "Geben Sie bitte eine gültige CPF-Nummer ein.",
        	creditcardtypes: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein.",
        	currency: "Geben Sie bitte eine gültige Währung ein.",
        	extension: "Geben Sie bitte einen Wert mit einer gültigen Erweiterung ein.",
        	giroaccountNL: "Geben Sie bitte eine gültige Girokontonummer ein.",
        	iban: "Geben Sie bitte eine gültige IBAN ein.",
        	integer:  "Geben Sie bitte eine positive oder negative Nicht-Dezimalzahl ein.",
        	ipv4: "Geben Sie bitte eine gültige IPv4-Adresse ein.",
        	ipv6: "Geben Sie bitte eine gültige IPv6-Adresse ein.",
        	lettersonly: "Geben Sie bitte nur Buchstaben ein.",
        	letterswithbasicpunc: "Geben Sie bitte nur Buchstaben oder Interpunktion ein.",
        	mobileNL: "Geben Sie bitte eine gültige Handynummer ein.",
        	mobileUK: "Geben Sie bitte eine gültige Handynummer ein.",
        	netmask:  "Geben Sie bitte eine gültige Netzmaske ein.",
        	nieES: "Geben Sie bitte eine gültige NIE-Nummer ein.",
        	nifES: "Geben Sie bitte eine gültige NIF-Nummer ein.",
        	nipPL: "Geben Sie bitte eine gültige NIP-Nummer ein.",
        	notEqualTo: "Geben Sie bitte einen anderen Wert ein. Die Werte dürfen nicht gleich sein.",
        	nowhitespace: "Kein Leerzeichen bitte.",
        	pattern: "Ungültiges Format.",
        	phoneNL: "Geben Sie bitte eine gültige Telefonnummer ein.",
        	phonesUK: "Geben Sie bitte eine gültige britische Telefonnummer ein.",
        	phoneUK: "Geben Sie bitte eine gültige Telefonnummer ein.",
        	phoneUS: "Geben Sie bitte eine gültige Telefonnummer ein.",
        	postalcodeBR: "Geben Sie bitte eine gültige brasilianische Postleitzahl ein.",
        	postalCodeCA: "Geben Sie bitte eine gültige kanadische Postleitzahl ein.",
        	postalcodeIT: "Geben Sie bitte eine gültige italienische Postleitzahl ein.",
        	postalcodeNL: "Geben Sie bitte eine gültige niederländische Postleitzahl ein.",
        	postcodeUK: "Geben Sie bitte eine gültige britische Postleitzahl ein.",
        	require_from_group: $.validator.format( "Füllen Sie bitte mindestens {0} dieser Felder aus." ),
        	skip_or_fill_minimum: $.validator.format( "Überspringen Sie bitte diese Felder oder füllen Sie mindestens {0} von ihnen aus." ),
        	stateUS: "Geben Sie bitte einen gültigen US-Bundesstaat ein.",
        	strippedminlength: $.validator.format( "Geben Sie bitte mindestens {0} Zeichen ein." ),
        	time: "Geben Sie bitte eine gültige Uhrzeit zwischen 00:00 und 23:59 ein.",
        	time12h: "Geben Sie bitte eine gültige Uhrzeit im 12-Stunden-Format ein.",
        	vinUS: "Die angegebene Fahrzeugidentifikationsnummer (VIN) ist ungültig.",
        	zipcodeUS: "Die angegebene US-Postleitzahl ist ungültig.",
        	ziprange: "Ihre Postleitzahl muss im Bereich 902xx-xxxx bis 905xx-xxxx liegen."
        } );
    } else {
        jQuery.extend(jQuery.validator.messages, {
            required: "This field is required.",
    		remote: "Please fix this field.",
    		email: "Please enter a valid email address.",
    		url: "Please enter a valid URL.",
    		date: "Please enter a valid date.",
    		dateISO: "Please enter a valid date (ISO).",
    		number: "Please enter a valid number.",
    		digits: "Please enter only digits.",
    		equalTo: "Please enter the same value again.",
    		maxlength: $.validator.format( "Please enter no more than {0} characters." ),
    		minlength: $.validator.format( "Please enter at least {0} characters." ),
    		rangelength: $.validator.format( "Please enter a value between {0} and {1} characters long." ),
    		range: $.validator.format( "Please enter a value between {0} and {1}." ),
    		max: $.validator.format( "Please enter a value less than or equal to {0}." ),
    		min: $.validator.format( "Please enter a value greater than or equal to {0}." ),
    		step: $.validator.format( "Please enter a multiple of {0}." )  
        },typeof translation == 'object' && typeof translation.form == 'object' ? translation.form : {});        
    }
} else {
    jQuery.extend(jQuery.validator.messages, {
    	required: "<i class='fa fa-times'></i> Dit is een verplicht veld.",
    	remote: "<i class='fa fa-times'></i> Dit e-mailadres is al bekend bij ons. <a href='#' onclick='gotoLogin(); return false;' style='color: red; text-decoration: underline'>Klik hier</a> om in te loggen.",
    	email: "<i class='fa fa-times'></i> Vul hier een geldig email adres in.",
    	url: "<i class='fa fa-times'></i> Dit is een verplicht veld.",
    	date: "<i class='fa fa-times'></i> Vul hier een geldige datum in.",
    	dateISO: "<i class='fa fa-times'></i> Vul hier een geldige datum in (ISO).",
    	number: "<i class='fa fa-times'></i> Vul hier een geldig nummer in.",
    	digits: "<i class='fa fa-times'></i> Vul hier alleen nummers in.",
    	creditcard: "<i class='fa fa-times'></i> Vul hier een geldig credit card nummer in.",
    	equalTo: "<i class='fa fa-times'></i> Vul hier dezelfde waarde in.",
    	accept: "<i class='fa fa-times'></i> Vul hier een waarde in met een geldige extensie.",
    	maxlength: "<i class='fa fa-times'></i> Vul hier maximaal {0} tekens in.",
    	minlength: "<i class='fa fa-times'></i> Vul hier minimaal {0} tekens in.",
    	rangelenth: "<i class='fa fa-times'></i> Vul hier een waarde in van minimaal {0} en maximaal {1} tekens.",
    	range: "<i class='fa fa-times'></i> Vul hier een waarde in van minimaal {0} en maximaal {1}.",
    	max: "<i class='fa fa-times'></i> Vul hier een waarde in kleiner dan of gelijk aan {0}.",
    	min: "<i class='fa fa-times'></i> Vul hier een waarde in groter dan of gelijk aan {0}.",
    },typeof translation == 'object' && typeof translation.form == 'object' ? translation.form : {});
}

var BrowserDetect = {		
	init			: function () 
	{
		this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
		this.version = this.searchVersion(navigator.userAgent)
			|| this.searchVersion(navigator.appVersion)
			|| "an unknown version";
		this.OS = this.searchString(this.dataOS) || "an unknown OS";
	},
	searchString	: function (data) 
	{
		for (var i=0;i < data.length;i++) {
			var dataString = data[i].string;
			var dataProp = data[i].prop;
			this.versionSearchString = data[i].versionSearch || data[i].identity;
			if (dataString) {
				if (dataString.indexOf(data[i].subString) != -1)
					return data[i].identity;
			}
			else if (dataProp)
				return data[i].identity;
		}
	},
	searchVersion	: function (dataString) 
	{
		var index = dataString.indexOf(this.versionSearchString);
		if (index == -1) return;
		return parseFloat(dataString.substring(index+this.versionSearchString.length+1));
	},
	getInfo			: function() 
	{
		// get the inner window size
		this.init();

		return {
		  	'Browser naam' 		: this.browser + ' (' + this.version + ')',
			'OS'				: this.OS,
			'Cookies toegestaan': ((navigator.cookieEnabled) ? 'Ja' : 'Nee'),
			'Java actief' 		: ((navigator.cookieEnabled) ? 'Ja' : 'Nee'),
			'UserAgent' 		: navigator.userAgent,
			'Scherm resolutie' 	: screen.width + 'x' + screen.height,
			'Window resolutie' 	: jQuery(window).width() + 'x' + jQuery(window).height()
		};
	},
	getInfoAsJSON	: function() 
	{
		var obj = this.getInfo();
		var first = true;
		var json = '{';
		for (prop in obj) {
			if (first) {
				first = false;
			} else {
				json += ',';
			}
			json += '"' + prop + '":"' + obj[prop].replace(/"/g, '\\"') + '"';
		}
		json += '}';
		return json;
	},
	dataBrowser: [
		{
			string: navigator.userAgent,
			subString: "Chrome",
			identity: "Chrome"
		},
		{ 	string: navigator.userAgent,
			subString: "OmniWeb",
			versionSearch: "OmniWeb/",
			identity: "OmniWeb"
		},
		{
			string: navigator.vendor,
			subString: "Apple",
			identity: "Safari",
			versionSearch: "Version"
		},
		{
			prop: window.opera,
			identity: "Opera"
		},
		{
			string: navigator.vendor,
			subString: "iCab",
			identity: "iCab"
		},
		{
			string: navigator.vendor,
			subString: "KDE",
			identity: "Konqueror"
		},
		{
			string: navigator.userAgent,
			subString: "Firefox",
			identity: "Firefox"
		},
		{
			string: navigator.vendor,
			subString: "Camino",
			identity: "Camino"
		},
		{		// for newer Netscapes (6+)
			string: navigator.userAgent,
			subString: "Netscape",
			identity: "Netscape"
		},
		{
			string: navigator.userAgent,
			subString: "MSIE",
			identity: "Explorer",
			versionSearch: "MSIE"
		},
		{
			string: navigator.userAgent,
			subString: "Gecko",
			identity: "Mozilla",
			versionSearch: "rv"
		},
		{ 		// for older Netscapes (4-)
			string: navigator.userAgent,
			subString: "Mozilla",
			identity: "Netscape",
			versionSearch: "Mozilla"
		}
	],
	dataOS : [
		{
			string: navigator.platform,
			subString: "Win",
			identity: "Windows"
		},
		{
			string: navigator.platform,
			subString: "Mac",
			identity: "Mac"
		},
		{
		   string: navigator.userAgent,
		   subString: "iPhone",
		   identity: "iPhone/iPod"
		},
		{
			string: navigator.platform,
			subString: "Linux",
			identity: "Linux"
		}
	]
};
jQuery(document).ready(function() {
	jQuery('form.auth_generated_form,form.generated_form').each(function() {
		jQuery(this).validate({
			ignoreTitle:true,
			submitHandler: function(form) {
				jQuery(form).find('[type=submit]').prop('readonly', 1).attr('disabled', 1).prop('cursor','pointer').addClass('disabled');
				form.submit();
			}
		});
		jQuery(this).find('input[name=js_check]').val(escape("wielinkwebsolutions"));
		jQuery(this).find('input[name=browser_info]').val(BrowserDetect.getInfoAsJSON());
	}); 
	jQuery('form.ajax_form').each(function() {
		if (jQuery(this).find('input[name=browser_info]').length > 0 && jQuery(this).find('input[name=browser_info]').val() == '') {
			jQuery(this).find('input[name=browser_info]').val(BrowserDetect.getInfoAsJSON());	
		}
	}); 
});