/**
 * You can use this in combination of scroll.
 * This is a example implementation.

 		wwdev.addScrollFunction(function(){
			if(jQuery("div.class:not(.initialized)").is(':onScreen') ) {
				jQuery('div.class').addClass('initialized');
				// do here your stuff
			}
		})

 *
 *
 */
(function(){
	"use strict";
	jQuery.expr[":"].onScreen = function(elem){
	    var win = jQuery(window),
	    	$this = jQuery(elem);

	    var viewport = {
		        top : win.scrollTop(),
		        left : win.scrollLeft()
		    };
	    viewport.right = viewport.left + win.width();
	    viewport.bottom = viewport.top + win.height();
	    
	    var bounds = $this.offset();
	    bounds.right = bounds.left + $this.outerWidth() - 100;
	    bounds.bottom = bounds.top + $this.outerHeight() - 100;
	    bounds.top -= 100;
	    bounds.bottom -= 100;
	    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
	};
})(jQuery);