;if (typeof wwdev == 'undefined') {
	var wwdev = {};
}

wwdev.getResponsiveWidth = function () {
	return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};
wwdev.reportError = function (e) {
	console.error(e);
};
wwdev.showLoading = function () {
	var container = '<div class="loader" style="width: 100px; height: 100px; top: 50%; margin-top: -50px; left: 50%; margin-left: -50px; position: absolute; z-index: 99;">';
	container += '<img src="/img/bx_loader.gif" />';
	container += '</div>';
	jQuery('body').append(container);
};
wwdev.hideLoading = function () {
	jQuery('div.loader').remove();
};
wwdev.zipcodeSearch = function (userOptions) {
	var options = jQuery.extend({
			zipcodeSelector: '#zipcode',
			streetSelector: '#street',
			streetNumberSelector: '#street_number',
			townSelector: '#town',
			ajaxSettings: {
				url: '/ajax_server.php/',
				type: 'GET',
				data: {
					req: 'getZipcode',
					app: 1
				},
				dataType: 'json'
			},
			countrySelector: null,
			defaultSuccess: true,
			searchZipcodeEnabled: null,
			customCallback: false,
			errorElem: jQuery("<label/>", {
				'class': 'error error_address_lookup',
			}).html('We konden uw combinatie van postcode en huisnummer niet vinden. Klopt deze wél? Ga dan verder.')
		}, typeof userOptions ? userOptions : {}),
		data = {
			zipcode: null,
			streetNumber: null,
		},
		xhr = null;

	if (options.errorElem.selector == '')
		jQuery(options.streetSelector).before(options.errorElem);

	options.errorElem.hide();

	function disabled() {
		jQuery(this).blur();
	}

	function selectorsDisabled(bool) {
		if (bool) {
			jQuery(options.townSelector + ',' + options.streetSelector).prop('readonly', true).css({
				background: '#f0f0f0',
				cursor: 'not-allowed'
			}).on('focus', disabled).addClass('readonly');
		} else {
			jQuery(options.townSelector + ',' + options.streetSelector).prop('readonly', false).css({
				background: '',
				cursor: '',
			}).off('focus', disabled).removeClass('readonly');
		}
	}

	if (options.countrySelector !== null) {
		jQuery(options.countrySelector).change(function () {
			if (options.searchZipcodeEnabled !== null && ((typeof options.searchZipcodeEnabled == 'boolean' && !options.searchZipcodeEnabled) || (typeof options.searchZipcodeEnabled == 'function' && options.searchZipcodeEnabled() === false))) {
				selectorsDisabled(false);
			} else {
				jQuery(options.zipcodeSelector).trigger('blur');
				selectorsDisabled(true);
			}
		});
	}

	if (options.searchZipcodeEnabled !== null && ((typeof options.searchZipcodeEnabled == 'boolean' && !options.searchZipcodeEnabled) || (typeof options.searchZipcodeEnabled == 'function' && options.searchZipcodeEnabled() === false))) {
		selectorsDisabled(false);
	} else {
		selectorsDisabled(true);
	}
	var self = this;
	jQuery(options.zipcodeSelector + ',' + options.streetNumberSelector).blur(function () {
		console.log('zipcode / streetnumber blur');
		if (options.searchZipcodeEnabled !== null && ((typeof options.searchZipcodeEnabled == 'boolean' && !options.searchZipcodeEnabled) || (typeof options.searchZipcodeEnabled == 'function' && options.searchZipcodeEnabled() === false))) {
			selectorsDisabled(false);
			return;
		}
		if (jQuery(options.zipcodeSelector).val() != '' && jQuery(options.streetNumberSelector).val() != '') {
			if (data.zipcode != jQuery(options.zipcodeSelector).val() || data.streetNumber != jQuery(options.streetNumberSelector).val()) {
				if (xhr !== null) {
					xhr.abort();
				}
				data.zipcode = jQuery(options.zipcodeSelector).val();
				data.streetNumber = jQuery(options.streetNumberSelector).val();

				selectorsDisabled(true);
				options.errorElem.hide();

				options.ajaxSettings.data = jQuery.extend(options.ajaxSettings.data, data);
				xhr = jQuery.ajax(options.ajaxSettings);
				if (options.defaultSuccess) {
					if (typeof options.customCallback == 'function') {
						xhr.done(function () {
							options.customCallback.apply(self, arguments);
						});
					} else {
						xhr.done(function (result) {
							jQuery(options.streetSelector).val(result.result.street);
							jQuery(options.townSelector).val(result.result.city);
							xhr = null;
							jQuery(options.streetSelector).trigger('blur');
							jQuery(options.townSelector).trigger('blur');
							jQuery(options.streetNumberSelector).trigger('blur');
							jQuery(options.zipcodeSelector).trigger('blur');
						});
					}
				}
				xhr.done(function (result) {
					if (!result.success) {
						options.errorElem.show();
						selectorsDisabled(false);
					}
				});
				xhr.fail(function () {
					options.errorElem.show();
					selectorsDisabled(false);
				});
			}
		} else if (jQuery(options.zipcodeSelector).val() == '' && jQuery(options.streetNumberSelector).val() == '') {
			// clear autocompleted fields
			jQuery(options.streetSelector).val('');
			jQuery(options.townSelector).val('');
			// clear temp caching
			data.zipcode = '';
			data.streetNumber = '';
			console.log('clear address fields due empty zipcode and streetnumber');
		}
	});
};
wwdev.addResizeFunction = function (callback, seconds, namespace) {
	var timer = typeof seconds == 'number' ? seconds : 300,
		resizeTimer;
	jQuery(window).on(namespace ? 'resize.' + namespace : 'resize', function () {
		if (typeof resizeTimer != 'undefined')
			clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {
			try {
				callback.apply(this, arguments);
			} catch (e) {
				wwdev.reportError(e);
			}
			;
		}, timer);
	});
};
wwdev.removeResizeFunction = function (namespace) {
	jQuery(window).off('resize.' + namespace);
}
wwdev.addScrollFunction = function (callback, seconds) {
	var timer = typeof seconds == 'number' ? seconds : 300,
		resizeTimer;
	jQuery(window).scroll(function () {
		if (typeof resizeTimer != 'undefined')
			clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {
			try {
				callback.apply(this, arguments);
			} catch (e) {
				wwdev.reportError(e);
			}
			;
		}, timer);
	});
};
wwdev.fixFooter = function (opt) {
	var config = jQuery.extend({
		footerSelector: 'footer',
		addResizeEvent: true
	}, typeof opt == 'object' ? opt : {});

	jQuery(config.footerSelector).css({
		position: '',
		bottom: '',
		width: ''
	});

	var viewportHeight = jQuery(window).height(),
		documentHeight = jQuery(document).height();


	if (viewportHeight >= documentHeight) {
		jQuery(config.footerSelector).css({
			position: 'fixed',
			bottom: 0,
			width: '100%'
		});
	}
	if (config.addResizeEvent) {
		wwdev.addResizeFunction(function () {
			wwdev.fixFooter(jQuery.extend(config, {
				addResizeEvent: false
			}));
		}, 0);
	}
};
wwdev.sprintf = function (str) {
	var data = typeof arguments[1] == 'object' ? arguments[1] : [];
	if (typeof arguments[1] != 'object') {
		for (var i = 1; i < arguments.length; i++) {
			data.push(arguments[i]);
		}
	}
	return str.replace(/\{(.*?)\}/g, function (wholeMatch, m1) {
		return data[m1] || wholeMatch;
	});
};
wwdev.addCookieBar = function (opt) {
	if (typeof opt == 'undefined') {
		opt = {};
	}
	if (!opt.siteName) {
		opt.siteName = '';
	}

	var config = jQuery.extend({
		siteName: '',
		emptyCSS: false,
        html: '',
		buttonText: 'Accepteer',
		content: opt.siteName + ' maakt gebruik van <a style="text-decoration: underline; color: white;" href="/cookiebeleid/">cookies</a> om de website goed te laten functioneren. Als je onze website gebruikt, gaan wij ervan uit dat je daarvoor toestemming geeft.'
	}, typeof opt === 'object' ? opt : {});

	var html = '<div class="cookie-bar" style="position: fixed; bottom: 0px; width: 100%; padding: 15px 0px; background: rgba(0,0,0,0.8); z-index: 999999">' +
		'<div class="cookie-bar-wrapper" style="margin: 0px auto; max-width: 1000px; padding: 0px 15px;">' +
		'<div class="cookie-bar-left" style="float: left; width: 70%; color: white; font-size: 13px; line-height: 1.3; padding-right: 30px;">' +
		config.content +
		'</div>' +
		'<div class="cookie-bar-right" style="float: right; width: 30%;">' +
		'<a class="btn" style="background-color: #000000; text-transform: uppercase; border: 1px solid black; padding: 5px 15px; border-radius: 2px; color: white; font-size: 13px; line-height: 2.3; width: 100%; display: inline-block; text-align: center; margin-top: 10px;" href="#">' + config.buttonText + '</a>' +
		'</div>' +
		'<div class="clear_div" style="clear: both; line-height: 1px;"></div>' +
		'</div><style>div.cookie-bar-wrapper * { box-sizing: border-box; } div.cookie-bar-wrapper a.btn:hover { background-color: white !important; color: black !important; }</style>' +
		'</div>';
	if (config.emptyCSS === true) {
		html = '<div class="cookie-bar">' +
			'<div class="cookie-bar-wrapper">' +
			'<div class="cookie-bar-left">' +
			config.content +
			'</div>' +
			'<div class="cookie-bar-right">' +
			'<a class="btn" href="#">' + config.buttonText + '</a>' +
			'</div>' +
			'<div class="clear_div"></div>' +
			'</div>' +
			'</div>';
	}
    if (config.html != "") {
        html = config.html;
    }

	try {
		if (typeof jQuery.cookie('cookies-accepted') === 'undefined') {
			jQuery('body').append(html);
			jQuery('.cookie-bar a.btn, div#cookie-notice a#cn-accept-cookie').click(function () {
				jQuery.cookie('cookies-accepted', '1', {expires: 365, path: '/'});
				jQuery('.cookie-bar').hide();
                jQuery('div#cookie-notice').hide();
				return false;
			});
		}
	} catch (error) {
		console.log(error);
		console.log('Unable to find the cookie lib.');
	}
};
wwdev.addCookieBarAdvanced = function(opt) {
	var config = jQuery.extend({
		name: 'wielink_avg',
		emptyCSS: false,
        position: 'bottom',
		options: {
			necessary: 'Noodzakelijk',
			analytical: 'Analytisch',
			tracking: 'Tracking'
		},
		inputLabel: 'Sta de volgende cookies toe',
		firstRequired: true,
		buttonText: 'Accepteren',
		reset: false,
		content: '<p>Wij maken gebruik van <a style="text-decoration: underline; color: white;" href="/cookiebeleid/">cookies</a> om de website goed te laten functioneren. <br>Als je onze website gebruikt, gaan wij ervan uit dat je daarvoor toestemming geeft.</p>'
	}, typeof opt === 'object' ? opt : {});

	/* options */
	var optionshtml = '';
	jQuery.each(config.options, function (key, value) {
		var rchecked = 'checked="false" class="not-checked"';
		if (config.firstRequired === true && optionshtml === '') {
			rchecked = 'checked="true" onclick="return false;" class="disabled checked"';
		}
		var st = '';
		optionshtml = optionshtml + '<li ' + st + '><input type="checkbox" name="' + key + '" value="1" ' + rchecked + ' id="cookie_' + key + '" /> <label class="checkbox_label" for="cookie-' + key + '">' + value + '</label></li>';
	});

	var html = '<div class="cookie-bar">' +
		'<div class="cookie-bar-wrapper">' +
		'<div class="cookie-bar-top">' +
		config.content +
		'</div>' +
		'<div class="cookie-bar-bottom">' +
		'<ul><li>' + config.inputLabel + '</li>' + optionshtml + '<li><a class="btn" href="#">' + config.buttonText + '</a> </li></ul>' +
		'</div>' +
		'<div class="clear_div"></div>' +
		'</div>' +
		'</div>';
	try {
		if (typeof jQuery.cookie(config.name + '_cookies_accepted') === 'undefined' || config.reset) {
            if (config.position == 'top') {
                jQuery('body').prepend(html);
            } else {
                jQuery('body').append(html);
            }
			// set the clicks
			jQuery(document).ready(function() {
				jQuery('div.cookie-bar').find('label.checkbox_label').click(function () {
					if (!(jQuery(this).prev().hasClass('disabled'))) {
						jQuery(this).toggleClass('inactive');
					}
				});
			});
			jQuery('.cookie-bar a.btn').click(function () {
				jQuery.each(config.options, function (key, value) {
					if (!(jQuery('#cookie_' + key).next().hasClass('inactive'))) {
						jQuery.cookie(config.name + '_cookies_' + key, '1', {expires: 365, path: '/'});
					}
				});
				jQuery.cookie(config.name + '_cookies_accepted', '1', {expires: 365, path: '/'});
				jQuery('.cookie-bar').hide();
				location.href = location.href;
				return false;
			});
		}
	} catch (error) {
		console.log(error);
		console.log('Unable to find the cookie lib.');
	}
};
wwdev.addCoronaBar = function(opt) {
    var config = jQuery.extend({
        name: 'wielink_corona',
        emptyCSS: false,
        position: 'bottom',
        buttonText: 'Afsluiten',
        reset: false,
        content: '<p>Lees <a href="https://www.smienktrapliften.nl/nieuws/smienk-coronavirus/">hier</a> de meest actuele informatie over Smienk en het coronavirus.</p>'
    }, typeof opt === 'object' ? opt : {});

    /* options */
    var html = '<div class="corona-bar">' +
        '<div class="corona-bar-wrapper">' +
        '<div class="corona-bar-top">' +
        config.content +
        '</div>' +
        '<div class="corona-bar-bottom">' +
        '<a class="btn" href="#">' + config.buttonText + '</a>' +
        '</div>' +
        '<div class="clear_div"></div>' +
        '</div>' +
        '</div>';
    try {
        if (typeof jQuery.cookie(config.name + '_cookies_accepted') === 'undefined' || config.reset) {
            if (config.position == 'top') {
                jQuery('body').prepend(html);
            } else {
                jQuery('body').append(html);
            }
            // set the clicks
            jQuery('.corona-bar a.btn').click(function () {
                jQuery.cookie(config.name + '_cookies_accepted', '1', {expires: 365, path: '/'});
                jQuery('.corona-bar').hide();
                location.href = location.href;
                return false;
            });
        }
    } catch (error) {
        console.log(error);
        console.log('Unknown error with corona-bar');
    }
};

export default wwdev;