wwdev.Bootstrap = {
	_c : function(cOptions) {
		if (typeof(cOptions) == 'undefined') cOptions = {};
		return cOptions;
	}
	,bxsliders: []
	,load: function(){}
	,run: function() { this._run();}
	,_run: function() {
		if( typeof wwdev.getResponsiveWidth != 'function' ) {
			throw 'Functions for wwdev not found';
			return ;
		}
		this.initMetaviewport();
		this.initLinks();
		this.initFancybox();
		this.initForms();
		this.initFormValidation();
	}
	,initBxSlider : function(cOptions) {
		return this._initBxSlider(cOptions);
	}
	,bxSliderOnSlideBefore: function(slideElement, oldIndex, newIndex) {
		var lazy = slideElement.find(".lazyBx");
		if (lazy.length > 0) {
			var lazyLoadContainer = 'original';
			if (wwdev.getResponsiveWidth() <= 640 && typeof lazy.attr('data-mobile') !== 'undefined') 
				lazyLoadContainer = 'mobile';
			var load = lazy.data(lazyLoadContainer);
			lazy.attr("src", load).removeClass("lazyBx");
			lazy.removeAttr('style');
		}
	}
	,_initBxSlider : function(cOptions) {

		if( typeof jQuery.fn.bxSlider != 'function' ) {
			throw 'library jQuery.bxSlider not found';
			return ;
		}
		var config = jQuery.extend({
				mode: 'fade',
				auto: null,
				pager: null,
				touchEnabled:null,
				pause : 7000,
				autoStart: null,
				controls: null,
				selector: '.bxslider',
				onSlideBefore: this.bxSliderOnSlideBefore
		        
			}, this._c(cOptions)),
			self = this;

		jQuery(config.selector).each(function() {
			/* You have to create a new variable of the config,
			 * otherwise after the first element the autoStart
			 * doesn't have to be null anymore */
			thisConfig = {};
			jQuery.extend(thisConfig, config, jQuery(this).data('bxslider') || {} );
			//console.log(thisConfig.controls, config.controls);
			if(thisConfig.autoStart === null)
				thisConfig.autoStart = (jQuery(this).children().length > 1);
			if(thisConfig.auto === null)
				thisConfig.auto = (jQuery(this).children().length > 1);
			if(thisConfig.pager === null)	
				thisConfig.pager = (jQuery(this).children().length > 1);
			if(thisConfig.controls === null)	
				thisConfig.controls = (jQuery(this).children().length > 1);
			if(thisConfig.touchEnabled === null)	
				thisConfig.touchEnabled = (jQuery(this).children().length > 1);

			self.bxsliders.push(jQuery(this).bxSlider(thisConfig));

		});

		return this.bxsliders;
	}	
	,initFancybox: function(cOptions) { 
		this._initFancybox( cOptions );
	}
	,_initFancybox: function( cOptions ) {
		if( typeof jQuery.fn.fancybox != 'function' ) { throw 'library jQuery.fancybox not found'; return; }
		var config = jQuery.extend({
			autoSize    : true,
            autoScale   : true,
            autoDimensions   : true,
			theme : 'light',
			selector : '.fancybox',
			helpers : {
				thumbs : true
			} 
		}, this._c(cOptions));
		
		jQuery(config.selector).fancybox(config);

	}
	,initForms: function() { 
		this._initForms();
		if ( jQuery('form#subscribenewsletter').length >= 1 ) {
			this._initFormNewsletter();
		}
	}
	,_initForms: function() {
		jQuery('input[name=js_check]').val('wielinkwebsolutions');
		 
		jQuery('input.submit').addClass('btn');
		
		jQuery("input.wwrel").blur(function() {
			if (this.value == '') 
				this.value = this.defaultValue;
		});
		jQuery("input.wwrel").focus(function() {
			if (this.value == this.defaultValue) 
				this.value = '';
		});
		
	}
	,_initFormNewsletter : function() {
		jQuery("form#subscribenewsletter input[type=text]").click(function() {
			if (this.value == jQuery(this).attr('data-defaultvalue'));
				this.value = '';
		});
		jQuery("form#subscribenewsletter").validate({
	   	    submitHandler: function(form) {
	   	    	jQuery(form).find('[type=submit]').prop('readonly', true).prop('disabled', true).css('cursor','pointer').addClass('disabled');
	    		if (form.email.value == "" || jQuery(form.email).attr('data-defaultvalue') == form.email.value ) {
	    		 	alert('Vul een (geldig) e-mailadres in.');
	    		 	jQuery('input[name=email]').focus();
	                return false;
	    		}
	     		jQuery.post('/ajax_server.php?app=frontpage&req=subscriberNewsletter',
	    	  	{ 
	    	  		data : jQuery(form).serialize()
	    		},
	    	  	function(data){
	    	  		if (data) {
	    		   		var parent = jQuery('form#subscribenewsletter').parent();
	    		   		jQuery(parent).html(data.text);
	    		   		return false;
	    		   	};
	    		}, 'json');
	      } 
	  	});
	}	
	,initFormValidation : function() {
		this._initFormValidation();
		this._initAjaxFormValidation();
	}
	,_initFormValidation : function(cOptions) {
		if( typeof jQuery.fn.validate != 'function' ) {
			throw 'library jQuery.validate not found';
			return ;
		}
		var config = jQuery.extend({}, this._c(cOptions));
		jQuery('form.validate_form').each(function() {
			jQuery('form.validate_form').each(function() {
				var groups = {};
				jQuery(this).find('input, select, textarea').each(function(){
					groupName = jQuery(this).data('error-group');
					if ( typeof( groupName) == 'undefined' ) {
						return;
					}
					if( typeof( groups[groupName]) == 'undefined') {
						groups[groupName] = jQuery(this).attr('name');
					} else {
						groups[groupName] += ' ' + jQuery(this).attr('name');
					}
				});
				jQuery(this).validate(jQuery.extend(config, {groups: groups}));
			});
		});
		
	}
	,_initAjaxFormValidation : function(options){
		if (typeof activeLanguage == 'undefined') {
			activeLanguage = 'nl';
		}
		var config = jQuery.extend({
			invalidHandler: function(){
				if(typeof jQuery.fancybox != 'undefined' && typeof jQuery.fancybox.update == 'function')
					jQuery.fancybox.update();
			},
			showErrors: function(){
				this.defaultShowErrors();
				if(typeof jQuery.fancybox != 'undefined' && typeof jQuery.fancybox.update == 'function')
					jQuery.fancybox.update();
			},
			submitHandler : function(form) {
				jQuery(form).find('[type=submit]').prop('readonly', true).prop('disabled', true).css('cursor','pointer').addClass('disabled');
				jQuery.ajax({
					url: '/ajax_server.php?app=frontpage&req=ajax_form&activeLanguage=' + activeLanguage,
					data: new FormData(form),
					type: 'POST',
					cache: false,
					contentType: false,
					processData: false,
					dataType: 'json',
					success: function(data){
						jQuery(form).parent().html(data.html);
					}
				});
				return false;
			},
		    unhighlight: function (element, errorClass, validClass) {
				if(!element.name) {
					console.log(' name niet gevonden', element );
					return true;
				}
		        $(element.form).find("label[for='" + element.name + "']")
		        .addClass("valid");
		    }
		},this._c(options));
		jQuery('form.ajax_form').each(function() {
			var parent = jQuery(this).parent();
			var groups = {};
			jQuery(this).find('input, select, textarea').each(function(){
				groupName = jQuery(this).data('error-group');
				if ( typeof( groupName) == 'undefined' ) {
					return;
				}
				if( typeof( groups[groupName]) == 'undefined') {
					groups[groupName] = jQuery(this).attr('name');
				} else {
					groups[groupName] += ' ' + jQuery(this).attr('name');
				}
			});
			jQuery(this).validate(jQuery.extend(config, {groups: groups}));
		});
	}
	,initLazyLoad : function(cOptions) {
		this._initLazyLoad(cOptions);
	}
	,lazyImageLoaded: function(){
		for(var i = 0; i < this.bxsliders.length; i++){
			this.bxsliders[i].redrawSlider();
		}
	}
	,_initLazyLoad : function(cOptions) {
		if( typeof jQuery.fn.lazyload != 'function' ) {
			throw 'library jQuery.lazyload not found';
			return ;
		}
		var self = this;
		var lazyLoadContainer = wwdev.getResponsiveWidth() < 767 ? 'mobile' : 'original';
		var config = jQuery.extend({
		    skip_invisible : false,
		    threshold : 200,
		    data_attribute	: lazyLoadContainer,
		    load: function(){
				self.lazyImageLoaded.apply(self);
			}
		}, this._c(cOptions));
		/* add the mobile tag if not present */
		jQuery('img.lazy').each(function() {
			if (typeof jQuery(this).attr('data-mobile') === 'undefined' && typeof jQuery(this).attr('data-original') !== 'undefined') {
				jQuery(this).attr('data-mobile', jQuery(this).data('original'));
			}		
		});
		jQuery("img.lazy").lazyload(config);
	}
	,initLinks: function() { 
		this._initLinks();
	}
	,_initLinks: function() {
		jQuery("a[href$='.pdf'],a[href^='http://'],a[href^='www.'],a[href^='https://'],a[data-rel='external']").not("a[href^='http://" + location.hostname + "']").not("a[href^='https://" + location.hostname + "']").attr('target','_blank');
	}
	,initMetaviewport: function() { 
		this._initMetaviewport();
	}
	,_initMetaviewport: function() {
		if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
		    var msViewportStyle = document.createElement("style");
		    msViewportStyle.appendChild(
		        document.createTextNode(
		            "@-ms-viewport{width:auto!important}"
		        )
		    );
		    document.getElementsByTagName("head")[0].
		        appendChild(msViewportStyle);
		}
		var f = document.getElementsByTagName("html"),
	        e = f.length ? f[0].className : "",
	        c = e.indexOf("ie7") > -1,
	        b = e.indexOf("ie8") > -1,
	        d;
	    if (c || b) {
	        return
	    }
	
	    function a() {
	        var i = navigator.userAgent,
	            h = "width=device-width, initial-scale=1.0, maximum-scale=1.5, minimum-scale=1.0",
	            j, g;
	        if (/android 2/i.test(i)) {
	            return
	        }
	        j = /OS [1-6]_[0-9_]* like Mac OS X/i;
	        g = /ipad/i.test(i);
	        if (g && j.test(i) && i.indexOf("AppleWebKit") > -1) {
	            return
	        }
	        if (g && document.getElementsByClassName("js-is-layout-responsive").length === 0) {
	            return
	        }
	        if (wwdev.getResponsiveWidth() <= 767) {
	            h = "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
	        }
	        try {
	        	document.querySelector('meta[name="viewport"]').setAttribute("content", h)
	        } catch (error) {
				// not all browsers support this selector
			}
	    }
	    a();
	    jQuery(window).resize(function() {
	        clearTimeout(d);
	        d = setTimeout(a, 200)
	    });
	}

	,initSuperfish : function(cOptions) { this._initSuperfish(cOptions)}
	,_initSuperfish : function(cOptions) {
		if( typeof jQuery.fn.bxSlider != 'function' ) {
			throw 'library jQuery.superfish not found';
			return ;
		}
		var config = jQuery.extend({
				selector:'.sf-menu',
				delay: 350,
				autoArrows : false,
				cssArrows: false,
				disableHI : false,
				hoverClass : 'sfHover'
			}, this._c(cOptions));
		jQuery(config.selector).superfish(config);
	}
	,initPlaceholder : function(configOptions) { this._initPlaceholder}
	,_initPlaceholder : function(cOptions) {
		if( typeof jQuery.fn.placeholder != 'function' ) {
			throw 'library jQuery.placeholder not found';
			return ;
		}
		var config = jQuery.extend({}, this._c(cOptions));
		jQuery('input, textarea').placeholder(config);
	}
	,initJplayer : function(files,selectors,config){
		if(typeof jPlayerPlaylist == 'undefined')
			throw 'jPlayer is not loaded.';
		new jPlayerPlaylist(jQuery.extend({
			jPlayer: "#jquery_jplayer_1",
			cssSelectorAncestor: "#jp_container_1"
		},this._c(selectors)),files,jQuery.extend({
			swfPath: "/js/lib/jquery/jPlayer/2.9.2/jquery.jplayer.swf",
			supplied: "oga, mp3",
			wmode: "window",
			useStateClassSkin: true,
			autoBlur: false,
			smoothPlayBar: true,
			keyEnabled: true
		},this._c(config)));
	}
	,addResizeFunction : function(callback, seconds){
		console.log('De functie addResizeFunction is verplaatst naar wwdev functions');
		return wwdev.addResizeFunction.apply(wwdev, arguments);
		var timer = typeof seconds == 'number' ? seconds : 300,
			resizeTimer;
		jQuery(window).resize(function(){
			if(typeof resizeTimer != 'undefined')
				clearTimeout(resizeTimer);
			resizeTimer = setTimeout(function(){
				callback.apply(this,arguments);
			},timer);
		});
	}
	,runOnLoad: function(){
		try{
			this.load();
		} catch(e){
			wwdev.reportError(e);
		};
	}
	,runOnReady: function(){
		try{
			this.run();
		} catch(e){
			wwdev.reportError(e);
		};
	}
};